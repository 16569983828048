import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useWS } from "@/ws";

import {
  getIsNotificationsListOpen,
  getNotificationsFromStore,
  getNotificationsFullDataLength,
  getUserId,
} from "../../ducks/Profile.selector";

import { Badge, Popover } from "@/uikit/components";
import { UserPanelIconWrapper, NotificationsIcon } from "../../Header.styled";
import Notification from "./Notification";
import {
  BlockWithoutNotifications,
  // CreateNotificationButton,
  NotificationsListWrapper,
  NotificationsScroll,
  NotifictionsPopperListWrapper,
} from "./Notifications.styled";

import {
  // NotificationHandleCreateProps,
  // Message,
  NotificationHandleDeleteProps,
  NotificationData,
} from "../../types";
import { useActionsNotifications } from "./ducks/Notifications.reducer";
import { WEBSOCKET_NAMES, WEBSOCKET_PATHS_SEND } from "@Store/constants";

const Notifications = () => {
  const userId = useSelector(getUserId());
  const {
    connected,
    data: dataWS,
    sendData,
    setData: updateWSData,
  } = useWS<NotificationData[]>(WEBSOCKET_NAMES.MESSAGE);

  const isOpen = useSelector(getIsNotificationsListOpen());
  const notificationsList = useSelector(getNotificationsFromStore());
  const wsCurrentFullDataLength = useSelector(getNotificationsFullDataLength());

  const notificationsActions = useActionsNotifications();

  const handleOpenPopper = useCallback(() => {
    notificationsActions.openNotificationsList();
  }, [notificationsActions]);

  const handleClosePopper = useCallback(() => {
    notificationsActions.closeNotificationsList();
  }, [notificationsActions]);

  useEffect(() => {
    if (userId && connected) {
      sendData({ path: WEBSOCKET_PATHS_SEND.MESSAGE.ECHO, body: `${userId}` });
    }

    return () => {
      notificationsActions.clearNotificationsList();
      updateWSData([]);
    };
  }, [userId, connected, sendData, notificationsActions, updateWSData]);

  useEffect(() => {
    if (dataWS) notificationsActions.wsUpdateNotifications(dataWS);
  }, [dataWS, notificationsActions]);

  const popperAnchorRef = useRef<HTMLDivElement | null>(null);

  const scrollRef = useRef<any>(null);

  const [notificationListHeight, setNotificationListHeight] = useState(68);

  useEffect(() => {
    if (scrollRef.current?.view?.firstChild) {
      const notificationsNodeList = scrollRef.current.view
        ?.firstChild as HTMLElement;

      const height = notificationsNodeList?.clientHeight
        ? Math.min(notificationsNodeList.clientHeight + 16, 442)
        : 68;

      setNotificationListHeight(height);
    }
  }, [notificationsList?.length, scrollRef, isOpen]);

  // const handleCreate: NotificationHandleCreateProps = useCallback(
  //   ({ text, projectIds, cityIds, departmentIds, jobIds }) => {
  //     let message: Message = {
  //       text,
  //       date: Date.now(),
  //       userId,
  //       projectIds,
  //       cityIds,
  //       departmentIds,
  //       jobIds,
  //     };
  //     for (const key of Object.keys(message)) {
  //       if (message[key] && message[key].length === 0) delete message[key];
  //     }
  //     sendData({
  //       path: WEBSOCKET_PATHS_SEND.MESSAGE.HELLO,
  //       body: JSON.stringify(message),
  //     });
  //   },
  //   [userId]
  // );

  const handleDelete: NotificationHandleDeleteProps = useCallback(
    (notificationId) => {
      const data = { userId, messageId: notificationId };
      sendData({
        path: WEBSOCKET_PATHS_SEND.MESSAGE.READ,
        body: JSON.stringify(data),
      });
      updateWSData(notificationsList.filter(({ id }) => id !== notificationId));
    },
    [userId, sendData, updateWSData, notificationsList]
  );

  const getTextOrLink = (text: string, link: string | null) =>
    text?.length ? text : link ?? "";

  return (
    <UserPanelIconWrapper ref={popperAnchorRef} onMouseEnter={handleOpenPopper}>
      <Badge
        count={wsCurrentFullDataLength}
        maxCount={99}
        horizontalPlacement="left"
        Anchor={<NotificationsIcon />}
      />
      <Popover
        open={isOpen}
        onClose={handleClosePopper}
        anchorEl={popperAnchorRef.current}
        marginThreshold={0}>
        <NotifictionsPopperListWrapper $height={notificationListHeight}>
          {dataWS?.length ? (
            <NotificationsScroll ref={scrollRef}>
              <NotificationsListWrapper>
                {/* todo: функционал для создания уведомлений пока не прописан в анализе */}
                {/* <CreateNotificationButton
                  onClick={() =>
                    handleCreate({ text: `<p>Текст ${Date.now()}</p>` })
                  }>
                  Создать уведомление
                </CreateNotificationButton> */}
                {notificationsList.map(
                  ({ id, text, name, secName, date, isRead, link }) => (
                    <Notification
                      key={id}
                      id={id}
                      text={getTextOrLink(text, link)}
                      name={name}
                      secName={secName}
                      date={date}
                      isRead={isRead}
                      onDelete={handleDelete}
                    />
                  )
                )}
              </NotificationsListWrapper>
            </NotificationsScroll>
          ) : (
            <BlockWithoutNotifications>
              Список уведомлений пуст
            </BlockWithoutNotifications>
          )}
        </NotifictionsPopperListWrapper>
      </Popover>
    </UserPanelIconWrapper>
  );
};

export default Notifications;
