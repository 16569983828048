import cloneDeep from "lodash/cloneDeep";
import assign from "lodash/assign";
import findIndex from "lodash/findIndex";
import concat from "lodash/concat";
import { unixtimeToString } from "@Helpers/HelpersDate";
import HelpersUrl from "@Helpers/HelpersUrl";

import { switchConfig } from "@Store/constants";

Object.assign(String.prototype, {
  stripHtmlTags() {
    const tmp = document.createElement("div");
    tmp.innerHTML = this;
    return tmp.textContent || tmp.innerText || "";
  },
  multiline() {
    return this.replace(/\n/g, "<br />");
  },
});

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

const toggleClassForce = (parentSelector, className, force) => {
  const el = document.querySelector(parentSelector);
  let classAdded = false;
  if (typeof force === "boolean") {
    if (force) {
      classAdded = true;
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  } else if (!el.classList.contains(className)) {
    classAdded = true;
    el.classList.add(className);
  } else {
    el.classList.remove(className);
  }
  return classAdded;
};

export function openMenu() {
  const hiddenSubmenu = window.localStorage.getItem("showSubmenu");
  if (hiddenSubmenu) {
    const el = document.querySelector("#workspace__root");
    el.classList.remove("hidden__submenu");
    window.localStorage.setItem("showSubmenu", "");
  }
}

(function () {
  if (typeof window.CustomEvent === "function") {
    return false;
  }

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    let evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

export function toggleSubmenuState(forceState) {
  const force =
    typeof forceState === "string" ? forceState === "hide" : undefined;
  const isSubmenuHidden = toggleClassForce(
    "#workspace__root",
    "hidden__submenu",
    force
  );
  window.localStorage.setItem(
    "showSubmenu",
    isSubmenuHidden ? "hidden__submenu" : ""
  );
  if (window.location.pathname.slice(1).split("/")[2] === "kms") {
    let event = new CustomEvent("showSubmenu", {
      detail: isSubmenuHidden ? "hidden__submenu" : "",
    });
    document.dispatchEvent(event);
  }
}

export function toggleRightBarState(forceState) {
  const force =
    typeof forceState === "string" ? forceState === "hide" : undefined;
  const isRightBarShown = toggleClassForce(
    "#workspace__root",
    "hidden__rightbar",
    force
  );
  window.localStorage.setItem(
    "rightBarShow",
    isRightBarShown ? "hidden__rightbar" : ""
  );
}

const _normalize = function (str) {
  str = str
    .replace(/(Ю\s|Б\s|Ь\s)/g, (s) => {
      return switchConfig.words[s];
    })
    .replace(/\s{2,}/g, " ")
    .trim();
  return str;
};

const _flip = function (trans) {
  let key;
  let tmp = {};
  for (key in trans) {
    if (trans.hasOwnProperty(key)) {
      tmp[trans[key]] = key;
    }
  }
  return tmp;
};

/**
 * Transliterate string according to settings
 *
 * @param str
 * @param settings
 */
export function getSwitch(str, settings = "engru") {
  const textToArray = str.split("");
  const obj =
    settings === "rueng"
      ? _flip(switchConfig.dictionary.keys)
      : switchConfig.dictionary.keys;

  let result = [];

  textToArray.forEach(function (sym, i) {
    if (obj.hasOwnProperty(textToArray[i])) {
      result.push(obj[textToArray[i]]);
    } else {
      result.push(sym);
    }
  });

  return _normalize(result.join(""));
}

export function detectAgent() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE");
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }
}

/**
 *  ==============================================================
 *  STRING HELPERS
 *  ==============================================================
 */

export function htmlDecode(input) {
  let fake = document.createElement("div");
  fake.innerHTML = input;
  return fake.childNodes[0] ? fake.childNodes[0].nodeValue : "";
}

/**
 *  ==============================================================
 *  DATE HELPERS
 *  ==============================================================
 */
export function convertDate(
  unixtime,
  numericDate = true,
  showTodayText = false,
  time = true
) {
  return unixtimeToString(unixtime, numericDate, showTodayText, time);
}

/**
 *  ==============================================================
 *  DATE HELPERS
 *  ==============================================================
 */

export const findDeep = (resourses, field, value, dontClone) => {
  let result = null;

  if (!resourses || resourses.length < 1) {
    return result;
  }

  for (let i in resourses) {
    if (resourses.hasOwnProperty(i)) {
      let resourse = resourses[i];

      if (resourse[field] === value) {
        result = dontClone ? resourse : cloneDeep(resourse);
      } else if (
        resourse.hasOwnProperty("items") &&
        resourse.items.length > 0
      ) {
        result = findDeep(resourse.items, field, value, dontClone);
      }
    }
    if (result !== null) {
      return result;
    }
  }

  return result;
};

export const findDeepIndex = (resourses, field, value) => {
  let result = [];

  if (!resourses || resourses.length < 1) {
    return result;
  }

  for (let i in resourses) {
    if (resourses.hasOwnProperty(i)) {
      let resourse = resourses[i];

      if (resourse[field] === value) {
        result.push(i);
      } else if (
        resourse.hasOwnProperty("items") &&
        resourse.items.length > 0
      ) {
        result = findDeepIndex(resourse.items, field, value);
      }
    }
    if (result.length > 0) {
      return result;
    }
  }

  return result;
};

export const findPedigree = function (resources, field, value) {
  let result = [];
  for (let i in resources) {
    if (resources.hasOwnProperty(i)) {
      let resource = resources[i];
      result.push(resource);
      if (resource[field] === value) {
        break;
      } else if (
        resource.hasOwnProperty("items") &&
        resource.items.length > 0
      ) {
        let childResult = findPedigree(resource.items, field, value);
        if (childResult.length === 0) {
          result.pop();
        } else {
          result = result.concat(childResult);
          break;
        }
      } else {
        result.pop();
      }
    }
  }
  return result;
};

/**
 * Find object by attribute value recursively
 * Alse return array of indexes. from 1st level to child
 *
 * @param array
 */
export const searchRecursive = function (items, attrName, attrValue) {
  if (!items || items.length < 1) {
    return null;
  }

  let objectReturn = null;
  let objectReturnIndex = [];

  for (let index in items) {
    if (+items[index][attrName] === +attrValue) {
      objectReturn = items[index];
      objectReturnIndex.unshift(+index);
      break;
    } else if (items[index].items && !objectReturn) {
      objectReturn = searchRecursive(items[index].items, attrName, attrValue);
      if (objectReturn) {
        if (objectReturn.hasOwnProperty("indexes")) {
          objectReturnIndex = objectReturn.indexes;
        }
        objectReturnIndex.unshift(+index);
      }
    }
  }

  if (objectReturn && objectReturnIndex.length > 0) {
    objectReturn.indexes = objectReturnIndex;
  }

  return objectReturn;
};

export const deleteRecursive = function (items, elementId) {
  elementId = parseInt(elementId, 10);
  if (isNaN(elementId)) {
    return items;
  }

  for (let key in items) {
    if (items[key].id === elementId) {
      items.splice(key, 1);
      break;
    } else if (items[key].items && items[key].items.length > 0) {
      items[key].items = deleteRecursive(items[key].items, elementId);
    }
  }

  return items;
};

export const insertRecursive = function (elements, element) {
  let items = elements.slice();

  if (element.parentId === 0) {
    let resultIndex = 0;
    for (let i = 0; i < 1; i++) {
      if (items[i].id === -1 || items[i].id === -2) {
        resultIndex++;
      }
    }
    items.splice(resultIndex, 0, element);
  } else {
    for (let key in items) {
      if (items[key].id === element.parentId) {
        items[key].items.push(element);
        break;
      } else if (items[key].items && items[key].items.length > 0) {
        items[key].items = insertRecursive(items[key].items, element);
      }
    }
  }

  return items;
};

export const changeRecursive = function (elements, element) {
  const items = elements.slice();

  for (let key in items) {
    if (items.hasOwnProperty(key)) {
      if (items[key].id === element.id) {
        items[key] = assign({}, items[key], element);
      } else if (items[key].items && items[key].items.length > 0) {
        items[key].items = changeRecursive(items[key].items, element);
      }
    }
  }

  return items;
};

export const replaceTreeElement = function (elements, element) {
  let items = elements.slice();

  if (!items || items.length < 1 || !element) {
    return null;
  }
  let oldElement = findDeep(items, "id", element.id);

  if (oldElement.parentId === element.parentId) {
    items = changeRecursive(elements, element);
  } else {
    deleteRecursive(items, element.id);
    items = insertRecursive(items, element);
  }

  return items;
};

export function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    let result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function sortChaptersByHtml(post) {
  const newChapters = [];
  const div = document.createElement("div");
  div.innerHTML = post.content;
  const htmlChapters = div.querySelectorAll(".chapter");

  for (let i = 0; i < htmlChapters.length; i++) {
    let index = findIndex(post.chapters, {
      url: `#${htmlChapters.item(i).id}`,
    });
    if (index >= 0) {
      newChapters.push(post.chapters[index]);
    }
  }
  return newChapters;
}

// то же самое что sortChaptersByHtml но принимает смонтированную статью
export function sortChaptersByHtmlMounted(post, chapters) {
  const newChapters = [];
  const htmlChapters = post.querySelectorAll(".chapter");
  for (let i = 0; i < htmlChapters.length; i++) {
    let index = findIndex(chapters, { url: `#${htmlChapters.item(i).id}` });
    if (index >= 0) {
      newChapters.push(chapters[index]);
    }
  }
  return newChapters;
}

// то же самое что sortChaptersByHtml но принимает SkEditor
export function sortChaptersByCKEDITOR(editor, chapters) {
  const newChapters = [];
  const htmlChapters = editor.querySelectorAll(".chapter");
  for (let i = 0; i < htmlChapters.length; i++) {
    let index = findIndex(chapters, { url: `#${htmlChapters.item(i).id}` });
    if (index >= 0) {
      newChapters.push(chapters[index]);
    }
  }
  return newChapters;
}

// проверка выбраны ли все чекбоксы
export const allChecked = (array) => array.every((obj) => obj.checked);

// проверка выбраны ли несколько чекбоксов
export const fewChecked = (array) =>
  array.find((object) => object.checked) !== undefined;

// округляем число

export const maxValueRounding = (num, add) => {
  return Math.round((num + add) / 10) * 10;
};

// находим максимальное число для отчетов

export const findReportsMaxValue = (arr) => {
  let maxVal = arr.reduce((acc, item) => {
    Object.keys(item).forEach((key) => {
      let intVal = parseInt(item[key], 10);
      if (key !== "date" && intVal > acc) {
        acc = intVal;
      }
    });
    return acc;
  }, 0);
  return maxValueRounding(maxVal, 5);
};

export const loginCheck = () => {
  const isLoggedIn = HelpersUrl.getRootRedirectPath();
  if (!isLoggedIn) {
    HelpersUrl.redirectToLogin();
  }
};

export const copyToClipboard = (str) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str);
  } else {
    const temp = document.createElement("INPUT");
    const focus = document.activeElement; // чтобы не терять фокус
    temp.value = str;
    document.body.appendChild(temp);
    temp.select();
    document.execCommand("copy"); // Магия! Копирует в буфер выделенный текст
    document.body.removeChild(temp);
    focus.focus();
  }
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === null || bytes === undefined) {
    return "Нет данных";
  }
  if (bytes === 0) {
    return "0 Б";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Б", "КБ", "МБ", "ГБ", "ТБ"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// Подготовка отступов уровней вложенности для селектов

export const prepareNestedOptions = (options, level = 1) => {
  let prepared = [];
  options.forEach((opt) => {
    prepared.push({
      ...opt,
      title: `${new Array(level).join("---")}  ${opt.title}`,
    });
    if (opt.items?.length) {
      prepared = concat(prepared, prepareNestedOptions(opt.items, level + 1));
    }
  });
  return prepared;
};

export const validateHTML = (htmlString) => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(htmlString, "application/xml");
  let errorNode = doc.querySelector("parsererror");

  return !errorNode;
};
