import React, { FC, useEffect, useState, useCallback, memo } from 'react';
import { usePopper } from 'react-popper';

import Icon from '../Icon';

import { IDropDown, DIRECTIONS } from './types';
import {
  DropDownWrapper,
  DropDownText,
  DropDownIcon,
  DropDownContentWrapper,
} from './DropDown.styled';

const DropDown: FC<IDropDown> = ({
  variant = 'filled',
  disabled = false,
  placeholder,
  leftIcon,
  rightIcon = true,
  direction = 'bottomLeft',
  onClick,
  closeOnClick,
  contentHeight = 'auto',
  contentWidth = 'auto',
  children
}) => {
  const [referenceElem, setReferenceElem] = useState<HTMLElement | null>(null);
  const [popperElem, setPopperElem] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { styles, attributes } = usePopper(referenceElem, popperElem, {
    placement: DIRECTIONS[direction],
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  },[setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  },[setOpen]);

  const toggleOpen = useCallback(() => {
    setOpen((open) => !open);
  },[setOpen]);

  const handleClick = useCallback(() => {
      onClick?.();
      if (closeOnClick) {
        toggleOpen();
      } else {
        handleOpen();
      }
  },[onClick, closeOnClick, toggleOpen, handleOpen]);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (referenceElem && !referenceElem.contains(e.target as Node)) {
      handleClose();
    }
    if (
      !closeOnClick && open && (e.target as HTMLElement).parentNode?.contains(referenceElem)
      && (e.currentTarget as HTMLElement)?.contains(referenceElem)
    ) {
      e.stopPropagation();
      handleClose();
    }
  },[closeOnClick, referenceElem, handleClose, open]);

  useEffect(() => {
    open && document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [open, handleClickOutside]);

  return (
    <DropDownWrapper
      ref={setReferenceElem}
      onClick={handleClick}
      variant={variant}
      $active={open}
      disabled={disabled}
    >
      {leftIcon && <Icon icon={leftIcon} />}
      <DropDownText variant={variant} $active={open} disabled={disabled}>{placeholder}</DropDownText>
      
      {rightIcon && <DropDownIcon $active={open} variant={variant} disabled={disabled} />}
      {open && 
        <DropDownContentWrapper
          ref={setPopperElem}
          $contentHeight={contentHeight}
          $contentWidth={contentWidth}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </DropDownContentWrapper>}
    </DropDownWrapper>
  )
}

export default memo(DropDown);