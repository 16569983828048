import { FC, memo, MouseEvent } from "react";
import { useSelector } from "react-redux";

import { useActionsSidebar } from "@/widgets/Sidebar/ducks/Sidebar.reducer";
import { getActiveCategory } from "@/widgets/Sidebar/ducks/Sidebar.selector";
import { useActionsSubMenu } from "../../ducks/SubMenu.reducer";
import {
  getCategoryOpened,
  getCategorySlice,
  getLoadingStatusCategories,
} from "../../ducks/SubMenu.selector";

import {
  SubmenuSectionContainer,
  SubmenuSectionTitle,
  SubmenuSectionWrapper,
  SubCategoryIcon,
  ToggleSubCategoryButton,
} from "./SubmenuSection.styled";
import { EditIconButton } from "./EditIconButton";
import Badge from "@/uikit/components/Badge/Badge";
import { LoadingSpinIndicator } from "./LoadingIndicator";
import { getCategoryIconType } from "@/widgets/Sidebar/utils";
import { Item, SubmenuSectionProps } from "./types";
import { SubmenuState } from "../../types";

const SubmenuSection: FC<SubmenuSectionProps> = ({
  lvl = 0,
  parentId = 0,
  linkPrefix,
  id,
  name,
  hasChildren,
  onToggle,
  isGlobalAdmin = false,
}) => {
  const notificationsCount = undefined;

  const openedIds = useSelector(getCategoryOpened());
  const activeEl = useSelector(getActiveCategory());

  const submenuActions = useActionsSubMenu();
  const sidebarActions = useActionsSidebar();

  const isOpen = openedIds?.[lvl] === id;
  const indexOfOpened = openedIds.findIndex((i) => i === parentId) + 1;
  const isArchiveChild = lvl > 0 && openedIds.includes(-4);
  const slicedOpenedIdsToCurrentLvl = openedIds.slice(0, indexOfOpened);

  const subCategories = useSelector(getCategorySlice(id, isArchiveChild));
  const isLoading = useSelector(getLoadingStatusCategories({ parentId: id }));

  const hasOwnChildren = ({ hasChildren, categories }: Item) =>
    hasChildren || (categories && categories.length > 0);

  const iconType = getCategoryIconType(id);

  const handleToggleOpen = () => {
    sidebarActions.setActiveMenuItem({
      isSubmenu: true,
      id,
      isArchive: isArchiveChild,
    });

    let openedTree: SubmenuState["categoryOpenedIds"] = [];
    const isCurrentAlreadyOpened = openedIds.includes(id);

    openedTree = isCurrentAlreadyOpened
      ? openedIds.slice(0, indexOfOpened + 1)
      : [...slicedOpenedIdsToCurrentLvl, id];

    if (!isOpen || indexOfOpened < openedIds.length) {
      submenuActions.toggleCategoryOpened(openedTree);

      if (!isArchiveChild && hasChildren && !isCurrentAlreadyOpened) {
        onToggle?.(id);
      }
    }
  };

  const handleToggleArrowIcon = (e: MouseEvent<HTMLButtonElement>) => {
    if (isOpen && id === activeEl?.id) {
      e.preventDefault();
      e.stopPropagation();
      submenuActions.toggleCategoryOpened(slicedOpenedIdsToCurrentLvl);
    } else {
      handleToggleOpen();
    }
  };

  const handleEdit = () => {
    alert("Будет реализовано редактирование категории");
  };

  return (
    <div id={`category_${id}`}>
      <SubmenuSectionContainer
        $lvl={lvl}
        $isActive={
          activeEl?.id === id && activeEl?.isArchive === isArchiveChild
        }
        to={`${linkPrefix}/${id}`}
        onClick={handleToggleOpen}>
        {hasChildren && (
          <ToggleSubCategoryButton
            lvl={lvl}
            isOpen={isOpen}
            onToggleOpen={handleToggleArrowIcon}
          />
        )}
        <SubCategoryIcon $isOpen={isOpen} $iconType={iconType} />
        <SubmenuSectionTitle>{name}</SubmenuSectionTitle>
        <SubmenuSectionWrapper>
          {isGlobalAdmin && <EditIconButton onClick={handleEdit} />}
          {((hasChildren && !isOpen) || !hasChildren) && (
            <Badge count={notificationsCount} color="tertiary" />
          )}
        </SubmenuSectionWrapper>
      </SubmenuSectionContainer>
      {isOpen &&
        (isLoading || (hasChildren && !subCategories) ? (
          <LoadingSpinIndicator />
        ) : (
          subCategories?.map((child) => (
            <SubmenuSection
              key={child.id}
              lvl={lvl + 1}
              linkPrefix={linkPrefix}
              id={child.id}
              parentId={id}
              name={child.name}
              hasChildren={hasOwnChildren(child)}
              onToggle={onToggle}
            />
          ))
        ))}
    </div>
  );
};

export default memo(SubmenuSection);
