import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { EditButton } from "@Widgets/Sidebar/Sidebar.styled";
import ToggleSubMenuListButton from "../../../ToggleCategoryButton";
import { TCategoryIcon } from "./types";
import { SectionContainerProps } from "./types";
import { FONTS } from "@/uikit/constants/Fonts";
import { COLORS } from "@/uikit/constants/Colors";

export const EditIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "EditS",
  color: COLORS.neutral400,
  size: "16",
})`
  min-width: 16px;
`;

export const SubmenuSectionTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-style: normal;
  color: ${COLORS.neutral700};
  font-size: ${FONTS.body4strong.fs};
  font-weight: ${FONTS.body4strong.weight};
  line-height: ${FONTS.body4strong.lh};
`;

export const SubCategoryIcon = styled(Icon).attrs<TCategoryIcon>(
  ({ $iconType, $isOpen }) => ({
    icon:
      $iconType === "Folder"
        ? $isOpen
          ? "FolderOpened"
          : "Folder"
        : $iconType,
    color: COLORS.neutral500,
  })
)<TCategoryIcon>`
  min-width: 20px;
  margin: ${({ $iconType }) => ($iconType === "Folder" ? "0 -1px 0 1px" : "0")};
`;

export const ToggleSubCategoryButton = styled(ToggleSubMenuListButton)``;

export const SubmenuSectionWrapper = styled.section`
  display: flex;
  margin-left: auto;
  align-self: center;
  align-items: center;
  gap: 8px;
`;

export const SubmenuSectionContainer = styled(NavLink)<SectionContainerProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: ${({ $lvl }) => `8px 16px 8px ${16 + 8 * $lvl}px`};
  border-radius: 8px;
  gap: 8px;

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${COLORS.accent50};

          ${SubmenuSectionTitle} {
            color: ${COLORS.accent500};
          }

          ${SubCategoryIcon} {
            path {
              fill: ${COLORS.accent500};
            }
          }

          ${ToggleSubCategoryButton} {
            path {
              fill: ${COLORS.accent400};
            }
          }

          ${EditButton} {
            display: none;
          }
        `
      : css`
          &:hover {
            background: ${COLORS.neutral50};
            transition: all 0.2s ease-out;

            ${SubCategoryIcon} {
              path {
                fill: ${COLORS.neutral700};
              }
            }

            ${EditButton} {
              display: inline-block;
            }
          }
        `}
`;
