import styled from "styled-components";
import ButtonBase from "@/uikit/components/ButtonBase";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { FONTS } from "@suz/ui-utils/constants/Fonts";
import { COLORS } from "@/uikit/constants/Colors";
import { UserAvatarStyledProps } from "../../types";

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
`;

export const UserAvatarStyled = styled.div<UserAvatarStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  min-width: 24px;
  min-height: 24px;
  color: ${COLORS.neutral0};
  border-radius: 12px;
  border: none;
  flex-shrink: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 4px 0 0;
  background: ${({ $avatar }) => ($avatar ? `url(${$avatar})` : COLORS.accent500)};
  background-size: 24px;
  background-position: center;
`;

export const UserName = styled.span`
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  font-weight: ${FONTS.body4strong.weight};
  color: ${COLORS.neutral500};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
`;

export const StyledButton = styled(ButtonBase)`
  width: 73px;
  background-color: ${COLORS.neutral0};
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  padding: 0;
  margin-left: 40px;
  cursor: pointer;
`;

export const ExitIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "Exit",
  size: "24",
})`
  min-width: 24px;
  min-height: 24px;
`;

export const StyledText = styled.span`
  color: ${COLORS.neutral500};
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  font-weight: ${FONTS.body4strong.weight};
`;
