import React, { FC, memo } from "react";
import { SkeletonWrapper } from "@Features/Skeleton/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import { UserWrapper } from "./User.styled";

interface SkeletonUserProps {
  isLoading: boolean;
  children?: JSX.Element;
}

const SkeletonUser: FC<SkeletonUserProps> = ({ isLoading, children }) => (
  <>
    {isLoading || !children ? (
      <SkeletonWrapper>
        <UserWrapper>
          <Skeleton
            circle
            width={24}
            height={24}
            style={{ marginRight: "4px" }}
          />
          <Skeleton width={70} height={16} />
        </UserWrapper>
      </SkeletonWrapper>
    ) : (
      children
    )}
  </>
);

export default memo(SkeletonUser);
