import { call, put, all, takeLatest } from "redux-saga/effects";
import apiClient from "@Helpers/apiClient";
import Cookies from "js-cookie";

import { actionsUser } from "./User.reducer";

function* mountUser() {
  yield put(actionsUser.startLoading());

  try {
    const userId = Cookies.get("WSUSERID");
    const USER_URL = `/v2/users/${userId}`;

    const {
      data: { code, response },
    } = yield call(apiClient.get, USER_URL);

    if (code === 200) {
      yield put(actionsUser.mountUser(response));
    } else {
      console.error("Неизвестная ошибка при разлогинивании пользователя");
      yield put(actionsUser.setError(true));
    }
  } catch (e) {
    yield put(actionsUser.setError(true));

    if (e instanceof Error) {
      console.error("Ошибка при запросе профиля пользователя", e.message);
    } else {
      console.error("Неизвестная ошибка при запросе профиля пользователя", e);
    }
  } finally {
    yield put(actionsUser.finishLoading());
  }
}

function* fetchUser() {
  yield takeLatest(actionsUser.fetchUser, mountUser);
}

export default function* FetchUserSaga() {
  yield all([fetchUser()]);
}
