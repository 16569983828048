import styled from "styled-components";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { SearchButtonProps } from "../../types";
import { COLORS } from "@/uikit/constants/Colors";
import { FONTS } from "@/uikit/constants/Fonts";

export const SearchIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "Search",
  color: COLORS.accent500,
})`
  margin-right: 8px;
`;

export const SearchField = styled.span`
  font-size: ${FONTS.body4strong.fs};
`;

export const SearchHotKeys = styled.span`
  margin-left: auto;
  font-size: ${FONTS.body4strong.fs};
`;

export const SearchWrapper = styled.button<SearchButtonProps>`
  display: flex;
  width: 260px;
  height: 40px;
  padding: 8px 16px;
  margin: 8px;
  color: ${COLORS.accent500};
  background-color: ${COLORS.neutral0};
  border: 1px solid ${COLORS.accent50};
  box-shadow: 0px 1px 11px 0px ${COLORS.accent500}29;
  border-radius: 8px;
  font-weight: ${FONTS.body4strong.weight};
  line-height: 142.857%;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${COLORS.accent25};
  }

  &:active {
    background-color: ${COLORS.accent75};
  }

  &:disabled {
    color: ${COLORS.neutral100};
    background-color: ${COLORS.neutral0};
    border: 1px solid ${COLORS.neutral100};
    box-shadow: none;
    cursor: not-allowed;

    ${SearchIcon} {
      path {
        fill: ${COLORS.neutral100};
      }
    }
  }
`;
