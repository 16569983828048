import { call, put, all, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { actionsLogin } from "./Login.reducer";
import { LdapAction, LdapApiResponse } from "../types";

function* LdapSaga({ type, payload }: PayloadAction<LdapAction>): Generator {
  yield put(actionsLogin.startLoading(type));

  const { onSuccess, onError } = payload;

  try {
    const response = yield call(fetch, "/ldap");
    if ((response as LdapApiResponse).status === 200) {
      onSuccess();
    } else {
      onError();
    }
  } catch (e) {
    onError();
    console.error("Ошибка при попытке аутентификации", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLdap() {
  yield takeEvery([actionsLogin.ldap], LdapSaga);
}
