import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createAction,
  createSlice,
  PayloadAction,
  bindActionCreators,
} from "@reduxjs/toolkit";

import { ProjectsState, ProjectsMenuFetchPayload } from "../types";
import { IProject } from "../components/Projects/types";

const initialState: ProjectsState = {
  projects: [],
  selectedProject: {} as IProject,
  isLoading: [],
};

const NAMESPACE = "[Projects]";

const fetchSelectedProject = createAction<ProjectsMenuFetchPayload>(
  `${NAMESPACE}/fetchSelectedProject`
);
const fetchProjects = createAction(`${NAMESPACE}/fetchProjects`);
const changeOrderProjects = createAction<IProject[]>(
  `${NAMESPACE}/changeOrderProjects`
);

const ProjectsSlice = createSlice({
  name: "[Projects]",
  initialState,
  reducers: {
    startLoading: (state, { payload }: PayloadAction<string>) => {
      state.isLoading.push(payload);
    },
    finishLoading: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = state.isLoading.filter((item) => item !== payload);
    },
    mountSelectedProject: (
      state,
      { payload: selectedProject }: PayloadAction<IProject>
    ) => {
      state.selectedProject = selectedProject;
    },
    mountProjects: (
      state,
      { payload: projects }: PayloadAction<IProject[]>
    ) => {
      state.projects = projects;
    },
    revertOrderProjects: (
      state,
      { payload: prevProjects }: PayloadAction<IProject[]>
    ) => {
      state.projects = prevProjects || initialState.projects;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const actionsProjects = {
  ...ProjectsSlice.actions,
  fetchSelectedProject,
  fetchProjects,
  changeOrderProjects,
};

export const useActionsProjects = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsProjects, dispatch),
    [dispatch]
  );
};

export default ProjectsSlice.reducer;
