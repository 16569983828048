import { createAction } from 'redux-actions';

const prefix = '[Favorites]';

export const fetchInitFavoritesList = createAction(`${prefix} fetchInitFavoritesList`);
export const mountInitFavoritesList = createAction(`${prefix} mountInitFavoritesList`);

export const fetchFolderFavoritesList = createAction(`${prefix} fetchFolderFavoritesList`);
export const mountFolderFavoritesList = createAction(`${prefix} mountFolderFavoritesList`);

export const fetchMoreFavorites = createAction(`${prefix} fetchMoreFavorites`);
export const mountMoreFavorites = createAction(`${prefix} mountMoreFavorites`);

export const fetchFavoritesFolders = createAction(`${prefix} fetchFavoritesFolders`);
export const mountFavoritesFolders = createAction(`${prefix} mountFavoritesFolders`);

export const setActiveFavoritesFolder = createAction(`${prefix} setActiveFavoritesFolder`);

export const setFavoritesToEditable = createAction(`${prefix} setFavoritesToEditable`);
export const setFavoritesToCreatable = createAction(`${prefix} setFavoritesToCreatable`);

export const createFolderInFavorites = createAction(`${prefix} createFolderInFavorites`);
export const mountFolderAfterCreate = createAction(`${prefix} mountFolderAfterCreate`);

export const editFolderInFavorites = createAction(`${prefix} editFolderInFavorites`);
export const mountFolderAfterEdit = createAction(`${prefix} mountFolderAfterEdit`);

export const deleteFolderFromFavorites = createAction(`${prefix} deleteFolderFromFavorites`);
export const mountFoldersAfterDelete = createAction(`${prefix} mountFoldersAfterDelete`);

export const deletePostFromFavorites = createAction(`${prefix} deletePostFromFavorites`);
export const mountListAfterDelete = createAction(`${prefix} mountListAfterDelete`);

export const fetchTags = createAction(`${prefix} fetchTags`);
export const mountTags = createAction(`${prefix} mountTags`);
export const setTags = createAction(`${prefix} setTags`);

export const abordFavorites = createAction(`${prefix} abordFavorites`);
export const catchFavoritesError = createAction(`${prefix} catchFavoritesError`);