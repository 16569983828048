import React, { useCallback } from "react";

export type RefItemType<T> =
  | ((element: T | null) => void)
  | React.MutableRefObject<T | null>
  | null
  | undefined;

export const useCombindeRef = <T>(...refs: RefItemType<T>[]) => {
  const refCb = useCallback(
    (element: T | null) => {
      refs.forEach((ref) => {
        if (!ref) return;

        typeof ref === "function" ? ref(element) : (ref.current = element);
      });
    },
    [refs]
  );

  return refCb;
};
