// @ts-nocheck
import { handleActions } from "redux-actions";
import {
  fetchNewsList,
  mountNewsList,
  fetchMoreNews,
  mountMoreNews,
  fetchNews,
  mountNews,
  markedNewsAsRead,
  abordNewsPreviewModal,
  abordNews,
  catchNewsError,
} from "./News.actions";
import { INewsState } from "../types";

const initialState: INewsState = {
  isLoading: false,
  newsList: {
    newsList: [],
    empty: false,
    first: false,
    last: false,
    isListLoading: false,
    pageNumber: 0,
  },
  newsModal: {
    content: null,
    isContentLoading: false,
    isRead: false,
    required: false,
  },
};

const NewsReducers = handleActions(
  {
    [fetchNewsList]: (state) => ({ ...state, isLoading: true }),
    [mountNewsList]: (state, { payload: { content, empty } }) => ({
      ...state,
      newsList: {
        ...state.newsList,
        newsList: content,
        empty: empty,
      },
      isLoading: false,
    }),
    [fetchMoreNews]: (state) => ({
      ...state,
      newsList: {
        ...state.newsList,
        isContentLoading: true,
      },
    }),
    [mountMoreNews]: (state, { payload: { content, number, last } }) => ({
      ...state,
      newsList: {
        ...state.newsList,
        newsList: [...state.newsList.newsList, ...content],
        pageNumber: number,
        isContentLoading: false,
        last: last,
      },
    }),
    [fetchNews]: (state) => ({
      ...state,
      newsModal: {
        ...state.newsModal,
        isContentLoading: true,
      },
    }),
    [mountNews]: (state, { payload: { content, isRead, required } }) => ({
      ...state,
      newsModal: {
        content: content,
        isContentLoading: false,
        isRead: isRead,
        required: required,
      },
    }),
    [markedNewsAsRead]: (state) => ({
      ...state,
      newsModal: {
        ...state.newsModal,
        isRead: true,
      },
    }),
    [abordNewsPreviewModal]: (state) => ({
      ...state,
      newsModal: initialState.newsModal,
    }),
    [abordNews]: (state) => ({
      ...initialState,
    }),
    [catchNewsError]: (state) => ({
      ...state,
      isLoading: false,
      hasError: true
    }),
  },
  initialState
);

export default NewsReducers;
