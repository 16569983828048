// @ts-nocheck
import { handleActions } from 'redux-actions';
import {
  fetchInitFavoritesList, 
  mountInitFavoritesList,
  fetchMoreFavorites,
  mountMoreFavorites,
  fetchFavoritesFolders,
  mountFavoritesFolders,
  setActiveFavoritesFolder,
  setFavoritesToEditable,
  setFavoritesToCreatable,
  mountFolderAfterCreate,
  mountFolderAfterEdit,
  mountFoldersAfterDelete,
  mountListAfterDelete,
  fetchFolderFavoritesList,
  mountFolderFavoritesList,
  fetchTags,
  mountTags,
  setTags,
  abordFavorites,
  catchFavoritesError
} from './Favorites.actions';

const initialState = {
  isLoading: false,
  activeFolder: null,
  isContentLoading: false,
  hasError: false,
  actions: {
    isEditable: false,
    isCreatable: false
  },
  favorites: {
    favoritesList: [],
    pageNumber: 0,
    empty: false,
    last: false,
  },
  folders: {
    isLoading: false,
    foldersList: [],
  },
  filters: {
    tags: {
      isLoading: false,
      filteredTags: [],
      tagsList: []
    },
    project: {}
  }
};

const FavoritesReducers = handleActions(
  {
    [fetchInitFavoritesList]: (state) => ({ ...state, isLoading: true }),
    [mountInitFavoritesList]: (state, { payload: { content, last, empty } }) => ({
      ...state,
      isLoading: false,
      favorites: {
        ...state.favorites,
        favoritesList: content,
        last: last,
        empty: empty,
      }
    }),
    [fetchMoreFavorites]: (state, { payload: { page } }) => ({
      ...state,
      isContentLoading: true,
      favorites: {
        ...state.favorites,
        pageNumber: page,
      }
    }),
    [mountMoreFavorites]: (state, { payload: { items, number, last } }) => ({
      ...state,
      isContentLoading: false,
      favorites: {
        ...state.favorites,
        favoritesList: [...state.favorites.favoritesList, ...items],
        pageNumber: number,
        last: last,
      }
    }),
    [fetchFavoritesFolders]: (state) => ({
      ...state, 
      folders: {
        ...state.folders,
        isLoading: true 
      } 
    }),
    [mountFavoritesFolders]: (state, { payload: items }) => ({
      ...state,
      folders: {
        ...state.folders,
        isLoading: false,
        foldersList: items
      }
    }),
    [setActiveFavoritesFolder]: (state, { payload: id }) => ({
      ...state,
      activeFolder: id
    }),
    [setFavoritesToEditable]: (state, { payload: isEditable }) => ({
      ...state,
      actions: {
        ...state.actions,
        isEditable: isEditable
      }
    }),
    [setFavoritesToCreatable]: (state, { payload: isCreatable }) => ({
      ...state,
      actions: {
        ...state.actions,
        isCreatable: isCreatable
      }
    }),
    [mountFolderAfterCreate]: (state, { payload: folder }) => ({
      ...state,
      folders: {
        ...state.folders,
        foldersList: [...state.folders.foldersList, folder ]
      }
    }),
    [mountFolderAfterEdit]: (state, { payload: { folderId, folderName }}) => {
      const foldersAfterEdit = state.folders.foldersList.map(
        (item) => item.id === folderId ? {...item, title: folderName} : item
      );
      return ({
      ...state,
      folders: {
        ...state.folders,
        foldersList: foldersAfterEdit
      }
    })},
    [mountFoldersAfterDelete]: (state, { payload: id }) => {
      const foldersAfterDelete = state.folders.foldersList.filter((item) => (
        item.id !== id 
      ));
    return ({
      ...state,
      folders: {
        ...state.folders,
        foldersList: foldersAfterDelete
      }
    })},
    [mountListAfterDelete]: (state, { payload: id }) => {
      const listAfterDelete = state.favorites.favoritesList.filter((item) => (
        item.id !== id 
      ));
      return ({
        ...state,
        favorites: {
          ...state.favorites,
          favoritesList: listAfterDelete
        }
      })},
    [fetchFolderFavoritesList]: (state) => ({
      ...state, 
      isLoading: true,
      hasError: false,
    }),
    [mountFolderFavoritesList]: (state, { payload: { content, last, empty } }) => ({
      ...state,
      isLoading: false,
      favorites: {
        ...state.favorites,
        favoritesList: content,
        last: last,
        empty: empty
      }
    }),
    [fetchTags]: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        tags: {
          ...state.filters.tags,
          isLoading: true
        }
      }
    }),
    [mountTags]: (state, { payload: items }) => ({
      ...state,
      filters: {
        ...state.filters,
        tags: {
          isLoading: false,
          tagsList: items
        }
      }
    }),
    [setTags]: (state, { payload: tags }) => ({
      ...state,
      filters: {
        ...state.filters,
        tags: {
          ...state.filters.tags,
          filteredTags: tags
        }
      }
    }),
    [catchFavoritesError]: (state) => ({
      ...state,
      isLoading: false,
      hasError: true
    }),
    [abordFavorites]: (state) => ({ 
      ...initialState
    }),
  },
  initialState,
);

export default FavoritesReducers;