import { createSelector } from "@reduxjs/toolkit";

import { SideBarRootState } from "@Widgets/Sidebar/types";

export const sideBarState = (state: SideBarRootState) => {
  return state.sideBar.sidebar;
};

export const getSidebarVisibility = () =>
  createSelector(sideBarState, (state) => state.sidebarShow);

export const getLockedCategoryAnchorButton = () =>
  createSelector(sideBarState, (state) => state.showCategoryAnchorButton);

export const getActiveProjectsMenuItem = () =>
  createSelector(sideBarState, (state) => {
    const { isSubmenu, id } = state.activeMenuItem;
    return !isSubmenu ? id : null;
  });

export const getActiveCategory = () =>
  createSelector(sideBarState, (state) => {
    const { isSubmenu, id, isArchive } = state.activeMenuItem;
    return isSubmenu ? { id, isArchive } : null;
  });
