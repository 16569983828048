import React, { FC, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from "./pages/NotFound";

import DocumentTitle from "./widgets/DocumentTitle";
import Loader from "@Features/Loader";

import GlobalStyle from "./GlobalStyle";
import { AppWrapper, AppWorkspace } from "./App.styled";

import Sidebar from "./widgets/Sidebar/Sidebar";
import Header from "./widgets/Header/Header";

import AuthRedirect from "@/utils/hocs/AuthRedirect";

const ProjectPage = lazy(() => import("./pages/ProjectPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));

const App: FC = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<AuthRedirect />} />
        <Route path="login/*" element={<LoginPage />} />
        <Route
          path="projects/:projectId?/*"
          element={
            <AuthRedirect>
              <AppWrapper>
                <Sidebar />
                <AppWorkspace>
                  <Header />
                  <DocumentTitle />
                  <Suspense fallback={<Loader />}>
                    <ProjectPage />
                  </Suspense>
                </AppWorkspace>
              </AppWrapper>
            </AuthRedirect>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
