import React, {
  FC,
  useEffect,
  useRef,
  useState,
  createContext,
  useCallback,
} from "react";
import Stomp from "stompjs";
import { useSelector } from "react-redux";
import { getUserId } from "@Widgets/Header/ducks/Profile.selector";
import { WSProviderType, UnknownWSContext, WatchersType } from "./types";
import axios from "axios";
import { getConnectionWS, useActionsWebSocket } from "./ducks/WS.reducer";

const protocolWS = window.location.protocol === "https:" ? "wss:" : "ws:";
const wsUrl = `${protocolWS}//${window.location.host}/data/websocket`;

export const WSContext = createContext<UnknownWSContext | undefined>(undefined);

export const WSProvider: FC<WSProviderType> = ({ children }) => {
  const [data, setData] = useState<UnknownWSContext["data"]>({});
  const [watchers, setWatchers] = useState<WatchersType>({});
  const websocket = useRef<UnknownWSContext["websocket"]["current"]>();
  const userId = useSelector(getUserId());
  const connected = useSelector(getConnectionWS());

  const actionsWS = useActionsWebSocket();

  const connectCallback = useCallback(() => {
    if (websocket?.current) {
      actionsWS.connectToWS(websocket.current);
      actionsWS.exchangeMessagesWS();
    }
  }, [websocket, actionsWS]);

  const errorCallback = useCallback(
    () => setTimeout(connectCallback, 0),
    [connectCallback]
  );

  const sessionHealthCheck = useCallback(() => {
    if (websocket.current && websocket.current?.connected) {
      axios
        .post("/data/system/heartbeat")
        .then(({ data }) => {
          data === "ok"
            ? actionsWS.setSystemStatusWS("online")
            : actionsWS.setSystemStatusWS("error");
        })
        .catch(() => {
          actionsWS.setSystemStatusWS("error");
        });
    }
  }, [websocket, actionsWS]);

  useEffect(() => {
    if (userId) {
      websocket.current = Stomp.client(wsUrl);
      websocket.current.heartbeat.outgoing = 25000;
      websocket.current.heartbeat.incoming = 25000;
      websocket.current.debug = () => {};
      websocket.current.connect(
        { "client-id": userId },
        connectCallback,
        errorCallback
      );

      const intervalId = setInterval(sessionHealthCheck, 25000);
      return () => {
        clearInterval(intervalId);
        websocket?.current?.disconnect(actionsWS.disconnectWS);
      };
    }
  }, [
    userId,
    actionsWS,
    sessionHealthCheck,
    connectCallback,
    errorCallback,
    websocket,
  ]);

  return (
    <WSContext.Provider
      value={{ connected, websocket, data, setData, watchers, setWatchers }}>
      {children}
    </WSContext.Provider>
  );
};
