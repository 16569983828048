export const REQUEST_CODE = {
  OK: 200,
  ACCEPTED: 202,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const KMS_CATEGORIES_IDS = {
  PROJECT_UPDATE: -1,
  FAVORITES: -2,
  DRAFTS: -3,
};

export const KMS_POST_STATUS = {
  DELETE: 0,
  ACTIVE: 1,
  POSTPONED: 2,
  DRAFT: 3,
  AUTOSAVE: 4,
  VERSION: 5,
  QUIZ: 6,
  MODERATION: 8,
};

export const KMS_POST_MODERATION_STATUS = {
  NEW: "NEW",
  PROCESSED: "PROCESSED",
  PUBLISHED: "PUBLISHED",
  TO_REWORK: "TO_REWORK",
  DECLINED: "DECLINED",
};

export const KMS_POST_ACTION = {
  CREATE: 1,
  EDIT: 2,
};

export const KMS_POST_LINK_TYPE = {
  EMPTY: 0,
  EXTERNAL: 1,
  INTERNAL_CROSS: 2,
  INTERNAL: 3,
  CHAPTER: 4,
};

export const KMS_PSO_STATUS = {
  DELETED: -1,
  NO_CHANGES: 0,
  CREATED: 1,
  EDITED: 2,
};

export const API_PATHS = {
  KMS: {
    CONFIG: "data/kms/config",
  },
};

export const FILES_VIEW_PATH = "/documents/view/";

export namespace WEBSOCKET_PATHS_SEND {
  export enum MESSAGE {
    HELLO = "/app/sendMessage",
    ECHO = "/app/echo",
    READ = "/app/read",
    READ_PUSH = "/app/readPush",
  }

  export const CATEGORIES_UPDATES = "/app/updates";
}

export namespace WEBSOCKET_NAMES {
  export const MESSAGE = "/user/messages";

  export namespace CATEGORIES {
    export const ALL = "/updates";
    export const PRIVATE = "/user/updates";
  }

  export namespace UPDATES {
    export const INIT = "init-categories-updates";
    export const WAIT = "wait-categories-updates";
  }

  export namespace NOTIFICATIONS {
    export const PUBLIC = "notifications-public";
    export const PRIVATE = "notifications-private";
  }

  export const TIMELINE = "timeline-updates";

  export namespace FEEDBACK {
    export const PUBLIC = "feedback-public";
    export const PRIVATE = "feedback-private";
  }

  export const FILES = "files-private";
  export const UPLOAD_DOCUMENT = "file-upload-document";
  export const UPLOAD_EDITOR = "file-upload-editor";
  export const CONVERT_EDITOR = "file-upload-editor-convert";
  export const REPLACE_FILE = "document-view-refresh";
}

/**
 * Array of Keyboards codes
 * @type Object
 */
export const keyCodes = {
  KEY_UP: 38,
  KEY_DOWN: 40,
};

export const switchConfig = {
  dictionary: {
    keys: {
      q: "й",
      w: "ц",
      e: "у",
      r: "к",
      t: "е",
      y: "н",
      u: "г",
      i: "ш",
      o: "щ",
      p: "з",
      "[": "х",
      "{": "Х",
      "]": "ъ",
      a: "ф",
      s: "ы",
      d: "в",
      f: "а",
      g: "п",
      h: "р",
      j: "о",
      k: "л",
      l: "д",
      ";": "ж",
      ":": "Ж",
      '"': "Э",
      "'": "э",
      z: "я",
      X: "Ч",
      c: "с",
      v: "м",
      b: "и",
      n: "т",
      m: "ь",
      ",": "б",
      ".": "ю",
      "<": "Б",
      ">": "Ю",
      Q: "Й",
      W: "Ц",
      E: "У",
      R: "К",
      T: "Е",
      Y: "Н",
      U: "Г",
      I: "Ш",
      O: "Щ",
      P: "З",
      A: "Ф",
      S: "Ы",
      D: "В",
      F: "А",
      G: "П",
      H: "Р",
      J: "О",
      K: "Л",
      L: "Д",
      Z: "?",
      x: "ч",
      C: "С",
      V: "М",
      B: "И",
      N: "Т",
      M: "Ь",
      "`": "ё",
    },
  },
  words: {
    "ю ": ". ",
    "б ": ", ",
    "Ь ": "ь ",
  },
};

export const DEFAULT_CITY = { id: -1, title: "Город не указан" };

export const DEFAULT_DEPARTMENT = { id: -1, title: "Подразделение не указано" };

export const DEFAULT_JOB = { id: -1, title: "Должность не указана" };

export const PAGE_SIZE = 50;

export const FEEDBACK_TICKET_STATUS = {
  NEW: "NEW",
  ACTIVE: "PROCESSED",
  CLOSED: "COMPLETED",
  PAUSE: "ON_PAUSE",
  DELETED: "DELETED",
  DECLINED: "DECLINED",
  TO_REWORK: "TO_REWORK",
  PUBLISHED: "PUBLISHED",
  DECLINED_MODERATION: "DECLINED_MODERATION",
  _values: [
    "NEW",
    "PROCESSED",
    "COMPLETED",
    "ON_PAUSE",
    "DELETED",
    "CANCELLED",
    "DECLINED",
    "TO_REWORK",
    "PUBLISHED",
    "DECLINED_MODERATION",
  ],
};

export const PUSH_TYPES = {
  NOTIFICATION: 0,
  INFO: 1,
  FEEDBACK: 2,
  FILE: 3,
  NEWS: 4,
  _values: [0, 1, 2, 3, 4],
};

export const FEEDBACK_MESSAGE_TYPE = {
  CLOSED: 0,
  CREATED: 1,
  APPOINTED: 2,
  DISMISSED: 3,
  CHANGED: 4,
  PAUSE: 5,
  ACTIVE: 6,
  MESSAGE: 10,
};

export const USER_SETTINGS_TYPE = {
  showUpdateWithoutComment: "showUpdateWithoutComment",
  showUpdateAboutCategories: "showUpdateAboutCategories",
  showWidgetFeedback: "showWidgetFeedback",
  showWidgetPhones: "showWidgetPhones",
  showWidgetEmailSms: "showWidgetEmailSms",
};
export const ALERT_TYPE = {
  TOWN_AND_ROLES: {
    title: "по городам, под��азделениям и должностям",
    value: 2,
  },
  PROJECTS: {
    title: "по проектам",
    value: 3,
  },
  ALL: {
    title: "всем пользователям системы",
    value: 1,
  },
};

export const MILLISECONDS_IN = {
  DAY: 86400000,
  HOUR: 3600000,
  MINUTE: 60000,
  SECOND: 1000,
};

export const TIMEZONES = [
  {
    title: "Калининград (-1 от Мск)",
    value: "Europe/Kaliningrad",
  },
  {
    title: "Москва (0 от Мск)",
    value: "Europe/Moscow",
  },
  {
    title: "Самара (+1 от Мск)",
    value: "Europe/Samara",
  },
  {
    title: "Екатеринбург (+2 от Мск)",
    value: "Asia/Yekaterinburg",
  },
  {
    title: "Омск (+3 от Мск)",
    value: "Asia/Omsk",
  },
  {
    title: "Красноярск (+4 от Мск)",
    value: "Asia/Krasnoyarsk",
  },
  {
    title: "Иркутск (+5 от Мск)",
    value: "Asia/Irkutsk",
  },
  {
    title: "Якутск (+6 от Мск)",
    value: "Asia/Yakutsk",
  },
  {
    title: "Владивосток (+7 от Мск)",
    value: "Asia/Vladivostok",
  },
  {
    title: "Магадан (+8 от Мск)",
    value: "Asia/Magadan",
  },
  {
    title: "Камчатка (+9 от Мск)",
    value: "Asia/Kamchatka",
  },
];

export const DEFAULT_DATE_TIME_FORMAT: Intl.DateTimeFormatOptions = {
  timeZone: 'UTC',
  dateStyle: 'short',
  timeStyle: 'short',
};

export const VARIABLE_PARAMETERS = [
  {
    title: 'Хранение информации по нажатию кнопки "Статья пригодилась"',
    value: "POST_LIKE_STORAGE_TIME",
  },
  {
    title: 'Хранение скачиваемых файлов в разделе "Выгрузка"',
    value: "DOWNLOADABLE_FILES_STORAGE_TIME",
  },
  { title: "Обновление оценки статей", value: "POST_RATING_REFRESH_FREQUENCY" },
  {
    title: "Ожидание оценки качества в разделе «Обратная связь»",
    value: "CLOSED_TICKET_RATING_TIMEOUT",
  },
  {
    title: "Ожидание подтверждения автором в разделе «Обратная связь»",
    value: "AUTHOR_CONFIRM_TICKET_TIMEOUT",
  },
  {
    title: "Время отображения push-уведомления",
    value: "DISPLAY_TIME_OF_PUSH_NOTIFICATIONS",
  },
];

export const VARIABLE_PARAMETERS_TIME = [
  {
    title: [
      { title: "Месяцы", value: "months" },
      { title: "Дни", value: "days" },
    ],
    value: "POST_LIKE_STORAGE_TIME",
  },
  {
    title: [
      { title: "Месяцы", value: "months" },
      { title: "Дни", value: "days" },
    ],
    value: "DOWNLOADABLE_FILES_STORAGE_TIME",
  },
  {
    title: [
      { title: "Дни", value: "days" },
      { title: "Часы", value: "hours" },
      { title: "Минуты", value: "minutes" },
    ],
    value: "POST_RATING_REFRESH_FREQUENCY",
  },
  {
    title: [
      { title: "Месяцы", value: "months" },
      { title: "Дни", value: "days" },
      { title: "Часы", value: "hours" },
    ],
    value: "CLOSED_TICKET_RATING_TIMEOUT",
  },
  {
    title: [
      { title: "Месяцы", value: "months" },
      { title: "Дни", value: "days" },
      { title: "Часы", value: "hours" },
    ],
    value: "AUTHOR_CONFIRM_TICKET_TIMEOUT",
  },
  {
    title: [{ title: "Секунды", value: "seconds" }],
    value: "DISPLAY_TIME_OF_PUSH_NOTIFICATIONS",
  },
];

export const VARIABLE_PARAMETERS_BOOLEAN = {
  JOURNAL_ENABLED: "JOURNAL_ENABLED",
  SENDING_NOTIFICATIONS: "SENDING_NOTIFICATIONS",
  ANONYMOUS_POSTING: "ANONYMOUS_POSTING",
  ACCESS_TO_MEMBERS_SECTIONS: "ACCESS_TO_MEMBERS_SECTIONS",
  ACCESS_TO_ADMINS_SECTIONS: "ACCESS_TO_ADMINS_SECTIONS",
  ACCESS_TO_SYNONYMS_SUBSECTIONS: "ACCESS_TO_SYNONYMS_SUBSECTIONS",
  DATE_TIME_AUTHOR_OF_ARTICLES: "DATE_TIME_AUTHOR_OF_ARTICLES",
  SHOW_GLOBAL_FILTERS: "SHOW_GLOBAL_FILTERS",
  PRINT_POSTS: "PRINT_POSTS",
  MODERATION: "MODERATION",
  GAMIFICATION: "GAMIFICATION",
};

export const DRAG_ITEM_DELAY = 200;
export const DRAG_ITEM_DELAY_OFF = 200000;

export const DEFAULT_FILESORT_PARAM = "fileDate,desc";

export const SEARCH_DEBOUNCE_DELAY = 250;
