import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  NotificationData,
  NotificationsDataState,
} from "@Widgets/Header/types";

const initialState: NotificationsDataState = {
  notifications: [] as NotificationData[],
  isLoading: false,
  error: false,
  isNotificationsListOpen: false,
};

const NAMESPACE = "[Notifications]";

const NotificationsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    setError: (state, { payload }: PayloadAction<boolean>) => {
      state.error = payload;
    },
    openNotificationsList: (state) => {
      state.isNotificationsListOpen = true;
    },
    closeNotificationsList: (state) => {
      state.isNotificationsListOpen = false;
    },
    wsUpdateNotifications: (
      state,
      { payload: newNotifications }: PayloadAction<NotificationData[]>
    ) => {
      const uniqueIds = new Set();
      state.notifications = (newNotifications ?? []).filter(
        (n: NotificationData) => {
          if (!uniqueIds.has(n.id)) {
            uniqueIds.add(n.id);
            return true;
          }

          return false;
        }
      );
    },
    clearNotificationsList: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const actionsNotifications = {
  ...NotificationsSlice.actions,
};

export const useActionsNotifications = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsNotifications, dispatch),
    [dispatch]
  );
};

export default NotificationsSlice.reducer;
