import { call, put, all, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsUser } from "./User.reducer";
import { LogoutData } from "@Widgets/Header/types";

function* logout({ payload: { onSuccess } }: PayloadAction<LogoutData>) {
  try {
    const { responseStatus } = yield call(apiClient.post, "/v2/logout");

    if (responseStatus === 200) {
      yield put(actionsUser.cleanUser());
      onSuccess();
    } else {
      console.error("Неизвестная ошибка при разлогинивании пользователя");
    }
  } catch (e) {
    console.error("Ошибка при разлогинивании пользователя", e);
  }
}

function* logoutUser() {
  yield takeLatest(actionsUser.logoutUser, logout);
}

export default function* LogoutSaga() {
  yield all([logoutUser()]);
}
