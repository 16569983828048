import styled, { css } from "styled-components";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { ClearButton } from "@Widgets/Sidebar/Sidebar.styled";
import { SubMenuWrapperProps, SubmenuTitleProps } from "./types";
import { COLORS } from "@/uikit/constants/Colors";
import { FONTS } from "@/uikit/constants/Fonts";

export const SubMenuCreateIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "PlusCircle",
  color: COLORS.neutral300,
})``;

export const SubMenuArrowUpIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "ArrowMUp",
  color: COLORS.neutral400,
})``;

export const SubMenuTitleButton = styled(ClearButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 10px;
  height: 20px;
  width: 20px;
`;

export const SubMenuListTitle = styled.span`
  display: flex;
  margin: 0px 8px;
  text-transform: uppercase;
  font-size: ${FONTS.subheading4strong.fs};
  font-weight: ${FONTS.subheading4strong.weight};
  letter-spacing: 0.36px;
  line-height: normal;
`;

export const SubMenuListTitleLeftPartContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;

  :hover {
    ${SubMenuListTitle} {
      color: ${COLORS.neutral500};
    }
  }
`;

export const SubMenuListTitleContainer = styled.div<SubmenuTitleProps>`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 12px 16px 8px 24px;
  position: sticky;
  bottom: 0;
  left: 0;

  ${({ $isBottomLocked }) =>
    $isBottomLocked
      ? css`
          background: ${COLORS.neutral50};
          border-top: none;

          ${SubMenuListTitleLeftPartContainer} {
            user-select: auto;
            cursor: pointer;
          }

          ${SubMenuListTitle} {
            color: ${COLORS.neutral400};
          }

          ${SubMenuTitleButton} {
            :not(:first-child) {
              margin-left: 16px;
            }
          }
        `
      : css`
          background: #f9fafb;
          border-top: 1px solid ${COLORS.neutral50};

          ${SubMenuListTitleLeftPartContainer} {
            user-select: none;
            cursor: auto;
          }

          ${SubMenuListTitle} {
            color: ${COLORS.neutral500};
          }

          ${SubMenuTitleButton} {
            :not(:first-child) {
              margin-left: 0;
            }
          }
        `}
`;

export const SubMenuWrapper = styled.div<SubMenuWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 276px;
  max-width: 396px;
  border-top: ${({ $isBottomLocked }) =>
    $isBottomLocked ? "1px solid ${COLORS.neutral50}" : "none"};
  font-size: ${FONTS.body4strong.fs};
  font-weight: ${FONTS.body4strong.weight};
  line-height: ${FONTS.body4strong.lh};
`;

export const SubMenuDragContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
`;
