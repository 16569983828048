import { DEFAULT_DATE_TIME_FORMAT } from '@Store/constants';

export const convertUnixTimeStamp =
  (timeStamp: Date, options: Intl.DateTimeFormatOptions = DEFAULT_DATE_TIME_FORMAT) => {
    return new Intl.DateTimeFormat("ru-RU", options)
      .format(timeStamp)
      ?.split(",")
      ?.join("");
};

export const unixtimeToString =(
  unixtime: string,
  numericDate = false,
  showTodayText = false,
  time = true
) => {
  if (!unixtime) return "";

  for (let i = unixtime.length; i < 13; i++) {
    unixtime += "0";
  }

  let $unixtime = parseInt(unixtime, 10);
  if (isNaN($unixtime)) return "";

  const dateObject = new Date($unixtime);

  const renderDate = dateObject.toLocaleString("ru-RU", {
    year: "numeric",
    month: numericDate ? "numeric" : "long",
    day: "numeric",
  });

  const renderTime = time
    ? ` ${dateObject.toLocaleString("ru-RU", {
        hour: "2-digit",
        minute: "2-digit",
      })}`
    : "";

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dayBeforeYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);

  return showTodayText && dateObject < dayBeforeYesterday
    ? `${dateObject < today ? "Вчера" : "Сегодня"}${renderTime}`
    : `${renderDate}${renderTime}`;
}
