import styled, { css } from "styled-components";
import { COLORS } from "@suz/ui-utils/constants/Colors";
import { CounterProps } from "./types";

export const BADGE_COLORS = {
  primary: COLORS["error500"],
  primaryFont: COLORS["neutral0"],
  secondary: COLORS["warning500"],
  tertiary: "transparent",
  tertiaryFont: COLORS["neutral500"],
  tertiaryBorder: COLORS["neutral300"],
};

export const Counter = styled.span<CounterProps>`
  box-sizing: border-box;
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  overflow: hidden;
  height: 20px;
  min-width: 20px;
  max-width: 38px;
  pointer-events: none;

  ${({ $hasAnchor, $verticalPlacement, $horizontalPlacement, offset }) =>
    $hasAnchor &&
    css`
      position: absolute;

      ${$verticalPlacement === "top" &&
      css`
        top: 0;
      `}
      ${$verticalPlacement === "bottom" &&
      css`
        bottom: 0;
      `}
      ${$verticalPlacement === "middle" &&
      css`
        top: 50%;
        transform: translateY(-50%);
      `}
      ${$horizontalPlacement === "left" &&
      css`
        right: ${offset};
      `}
        ${$horizontalPlacement === "right" &&
      css`
        left: ${offset};
      `}
      ${$horizontalPlacement === "center" &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `}
    `}

  background-color: ${({ color }) => BADGE_COLORS[color]};
  color: ${BADGE_COLORS["primaryFont"]};

  ${({ color }) =>
    color === "tertiary" &&
    css`
      color: ${BADGE_COLORS.tertiaryFont};
      border: 1px solid ${BADGE_COLORS.tertiaryBorder};
    `}
`;
