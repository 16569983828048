import Cookies from "js-cookie";

import { IProject } from "./components/ProjectsMenu/components/Projects/types";
import { TextWidthProps } from "./types";
import { ICompareWithStringify } from "./components/ProjectsMenu/types";

const authCookies = {
  param: "WSUSERPERMISSIONS",
  isAdmin: "GLOBAL-ADMIN",
};

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

export const hasGlobalAdminAccess = () => {
  return getCookie(authCookies.param) === authCookies.isAdmin;
};

export const getContrastColor = (color: string) => {
  color = color.replace("#", "");

  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  const colorLuminance = (r * 299 + g * 587 + b * 114) / 1000;

  return colorLuminance >= 150 ? "#000" : "#fff";
};

export const getCategoryIconType = (
  id: number
): "Folder" | "Draft" | "Archives" => {
  const iconTypes: Record<number, "Draft" | "Archives"> = {
    [-3]: "Draft",
    [-4]: "Archives",
  };
  return iconTypes[id] || "Folder";
};

export const getInitialsFromName = (name: string) => {
  if (!name || typeof name !== "string") return "";
  const [first = "", second = ""] = name.toUpperCase().split(" ");
  return `${first.charAt(0)}${second.charAt(0)}`;
};

export const measureTextWidth = ({
  text,
  font = "14px Inter, sans-serif",
}: TextWidthProps) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Cannot create 2D context");
  }
  ctx.font = font;
  const metrics = ctx.measureText(text);
  return metrics.width;
};

export const findMaxWidthText = (texts: TextWidthProps[]) => {
  let maxWidth = 0;
  let maxWidthText = "";
  texts.forEach(({ text, font }) => {
    const textWidth = Math.ceil(measureTextWidth({ text, font }));
    if (textWidth > maxWidth) {
      maxWidth = textWidth;
      maxWidthText = text;
    }
  });
  return { maxWidth, maxWidthText };
};

export const compareWithStringify = ({
  first,
  second,
}: ICompareWithStringify) => {
  return JSON.stringify(first) !== JSON.stringify(second);
};

// todo: поискать более простое решение для определения ширины ScrollBox
export const getScrollBoxWidth = (
  projects: IProject[],
  isGlobalAdmin: boolean
) => {
  if (!projects) return;

  const texts = projects.flatMap(({ name, roleName }) => [
    { text: name, font: "14px Inter, sans-serif" },
    { text: roleName, font: "10px Inter, sans-serif" },
  ]);
  const { maxWidth } = findMaxWidthText(texts);

  const maxWidthAndPaddingRight = maxWidth + 8;
  const minSize = isGlobalAdmin ? 164 : 196;
  const maxSize = isGlobalAdmin ? 286 : 318;
  const textMargin = isGlobalAdmin ? 116 : 84;

  if (maxWidthAndPaddingRight <= minSize) return 280;
  if (maxWidthAndPaddingRight >= maxSize) return 400;
  return maxWidthAndPaddingRight + textMargin;
};
