import { FC, ForwardedRef, memo } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { ClearButton } from "@Widgets/Sidebar/Sidebar.styled";
import { EditIconButton } from "../../../SubMenu/components/SubmenuSection/EditIconButton";
import {
  CreateProjectButtonProps,
  PopperListWrapperProps,
  ProjectImageProps,
  ProjectListItemContainerProps,
  ProjectsWrapperProps,
} from "./types";
import { COLORS } from "@/uikit/constants/Colors";
import { FONTS } from "@/uikit/constants/Fonts";

export const DragIconContainer = styled.div`
  position: relative;
`;

export const UnfoldProjectMenuIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "ArrowsUpDown",
  color: COLORS.neutral400,
})`
  transition: color 0.2s ease-out;
`;

export const UnfoldProjectMenuButton = styled(ClearButton)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const ProjectsWrapper = styled.div<ProjectsWrapperProps>`
  padding: 12px 24px;
  cursor: pointer;

  :hover {
    ${UnfoldProjectMenuIcon} {
      path {
        fill: ${COLORS.neutral600};
      }
    }
  }
`;

export const PopperListWrapper = styled.div<PopperListWrapperProps>`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 80px);
  font-family: Inter, sans-serif;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  padding: ${({ $isEmptyProjects }) =>
    $isEmptyProjects ? "12px 0px" : "12px 0px 4px"};
  background: ${COLORS.neutral0};
  box-shadow: 0px 0px 15px 0px #00000033;
  border-radius: 8px;
  min-width: 280px;
  max-width: 400px;
  box-sizing: border-box;
  transition: opacity 200ms ease-out;
`;

export const ProjectImage = styled.div<ProjectImageProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 125%;
  width: 36px;
  height: 36px;
  background-size: 36px;
  background-color: ${({ $iconColor }) =>
    $iconColor ? `#${$iconColor}` : COLORS.accent500};
  color: ${({ $contrastColor }) => $contrastColor};
  background-image: ${({ $backgroundImage }) => $backgroundImage};
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral100};
  flex-shrink: 0;
  margin: 0 12px 0 0;
  pointer-events: none;
`;

export const ProjectTitle = styled.span`
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  color: ${COLORS.neutral700};
`;

export const ProjectJobTitle = styled.span`
  font-size: ${FONTS.caption2strong.fs};
  line-height: ${FONTS.caption2strong.lh};
  color: ${COLORS.neutral400};
`;

export const ProjectInfoText = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 156px;
  max-width: 280px;

  ${ProjectTitle}, ${ProjectJobTitle} {
    font-weight: ${FONTS.body4strong.weight};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 208px;
  max-width: 328px;
`;

export const DragAndDropWrapper = styled.div<{
  ref: ForwardedRef<HTMLDivElement>;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DragAndDropIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "DragVertical",
  color: "#b1b1b4",
})`
  position: absolute;
  top: 16px;
  left: 4px;
`;

const CreateProjectIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "PlusCircle",
  color: COLORS.neutral300,
})``;

export const CreateProjectButton: FC<CreateProjectButtonProps> = memo(
  ({ onClick, className }) => (
    <ClearButton onClick={onClick} className={className}>
      <CreateProjectIcon />
    </ClearButton>
  )
);

export const AddProjectButtonStyled = styled(CreateProjectButton)`
  transition: color 0.2s ease-out;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-shrink: 0;
`;

export const EditIconButtonStyled = styled(EditIconButton)`
  transition: color 0.2s ease-out;
  display: flex;
  opacity: 0;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 8px;
  flex-shrink: 0;
`;

export const ProjectHover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const ProjectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

export const ProjectsSection = styled(ProjectContainer)`
  flex-shrink: 0;
  width: 100%;
  height: 24px;
  padding: 0px 12px 0px 28px;
  justify-content: space-between;

  div {
    cursor: context-menu;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.03em;
    user-select: none;
    color: ${COLORS.neutral300};
  }

  ${AddProjectButtonStyled} {
    background: ${COLORS.neutral50};
    color: ${COLORS.neutral300};

    &:hover {
      background: ${COLORS.neutral75};
    }
  }
`;

export const ProjectListItemContainer = styled(
  NavLink
)<ProjectListItemContainerProps>`
  ${({ $isActive, $isDraggingOver, $isGlobalAdmin }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 100%;
    height: 52px;

    ${ProjectHover} {
      transition: color 0.2s ease-out;
      margin: 0 4px;
      justify-content: space-between;
      background: ${$isActive ? COLORS.accent50 : COLORS.neutral0};
      cursor: ${$isDraggingOver ? "inherit" : "pointer"};

      ${ProjectInfo} {
        max-width: ${$isGlobalAdmin ? "352px" : "384px"};
        margin-left: 24px;
      }

      ${ProjectImage} {
        margin: 0 12px 0 0;
      }

      ${ProjectInfoText} {
        max-width: ${$isGlobalAdmin ? "284px" : "316px"};
        min-width: ${$isGlobalAdmin ? "156px" : "196px"};
        margin-left: 4px;
        padding-right: ${$isGlobalAdmin ? "0" : "8px"};

        ${ProjectTitle} {
          color: ${$isActive ? COLORS.accent500 : COLORS.neutral1000};
        }

        ${ProjectJobTitle} {
          color: ${$isActive ? COLORS.accent400 : COLORS.neutral400};
        }
      }

      ${!$isDraggingOver &&
      css`
        &:hover {
          background: ${$isActive ? COLORS.accent75 : COLORS.neutral50};
          ${EditIconButtonStyled} {
            opacity: 1;
            color: ${$isActive ? COLORS.accent400 : COLORS.neutral400};
          }
        }
      `}

      &:active {
        background: ${$isActive ? COLORS.accent100 : COLORS.neutral100};
      }
    }
  `}
`;
