import { FC, memo } from "react";

import { ClearButton } from "@Widgets/Sidebar/Sidebar.styled";
import { DeleteNotificationIcon } from "./Notifications.styled";
import { DeleteNotificationIconButtonProps } from "../../types";

const DeleteIconButton: FC<DeleteNotificationIconButtonProps> = ({
  onClick,
  disabled = false,
  className,
}) => (
  <ClearButton onClick={onClick} className={className}>
    <DeleteNotificationIcon disabled={disabled} />
  </ClearButton>
);

export default memo(DeleteIconButton);
