import { all } from "redux-saga/effects";

import Auth from "./Auth.saga";
import Ldap from "./Ldap.saga";
import Reset from "./ResetPass.saga";
import DefaultProject from "./DefaultProject.saga";

export default function* projectsSaga() {
  yield all([Auth(), Ldap(), Reset(), DefaultProject()]);
}
