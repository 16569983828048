import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsLogin } from "./Login.reducer";
import { DefaultProjectAction } from "../types";

function* defaultProjectSaga({
  type,
  payload,
}: PayloadAction<DefaultProjectAction>) {
  yield put(actionsLogin.startLoading(type));
  const { userId, onSuccess, onError } = payload;
  try {
    const { responseStatus, data } = yield call(
      apiClient.get,
      `/v2/users/${userId}/default-project`
    );
    if (responseStatus === 200 && data.code === 200) {
      const { response } = data;
      if (response.id) {
        yield put(actionsLogin.setDefaultProject(response));
        onSuccess(response.id);
      } else {
        onError();
      }
    } else {
      onError();
    }
  } catch (e) {
    onError();
    console.error("Ошибка при попытке получения default project", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLogin() {
  yield takeLatest(actionsLogin.getDefaultProject, defaultProjectSaga);
}
