import { useEffect, useContext, useRef, useCallback } from "react";
import { WSContext } from "./WsContext";
import { IWebSocketMessage, UseWSReturn, WSContextType } from "./types";
import { useActionsWebSocket } from "./ducks/WS.reducer";

export const useWS = <T>(url: string): UseWSReturn<T> => {
  const { connected, data, setData, setWatchers } = useContext(
    WSContext
  ) as WSContextType<T>;
  const actionsWS = useActionsWebSocket();

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const sendData = useCallback(
    ({ headers = {}, body, path = "" }: IWebSocketMessage) => {
      actionsWS.sendMessageToWS({
        destination: path || url,
        headers,
        data: body,
      });
    },
    [actionsWS, url]
  );

  const setCurrentData = useCallback(
    (payload: T) => {
      setData((prevData) => ({
        ...prevData,
        [url]: payload,
      }));
    },
    [setData, url]
  );

  useEffect(() => {
    setWatchers((prevState) => {
      const value = Number.isNaN(+prevState[url]) ? 0 : prevState[url];
      return {
        ...prevState,
        [url]: value + 1,
      };
    });
  }, [url, setWatchers]);

  useEffect(() => {
    actionsWS.subscribeWS({
      destination: url,
      callback: ({ body }) => {
        const payload = JSON.parse(body);
        const isArrayPayload = Array.isArray(payload);
        const getFinalPayload = <T>(prevState: Record<string, T>) => {
          const storeData = prevState[url];

          return !storeData || !isArrayPayload
            ? payload
            : [...(storeData as T[]), ...payload];
        };

        setData((prevState) => ({
          ...prevState,
          [url]: getFinalPayload<T>(prevState),
        }));
      },
      id: url,
    });
  }, [connected, url, actionsWS, setData]);

  useEffect(
    () => () => {
      setWatchers((prevState) => {
        const nextWatchers = prevState[url] - 1;

        if (nextWatchers === 0) actionsWS.unsubscribeWS({ id: url });

        return {
          ...prevState,
          [url]: nextWatchers,
        };
      });

      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    },
    [url, setWatchers, actionsWS]
  );

  return {
    connected,
    sendData,
    data: data[url],
    store: data,
    setData: setCurrentData,
  };
};
