import { createSelector } from "@reduxjs/toolkit";
import { ProfileUserState } from "../types";

const profileUserState = (state: ProfileUserState) => state.profile.user;

const notificationsState = (state: ProfileUserState) =>
  state.profile.notifications;

export const getIsLoadingUser = () =>
  createSelector(profileUserState, (state) => state.isLoading);

export const getUser = () =>
  createSelector(profileUserState, (state) => state.userData);

export const getUserId = () =>
  createSelector(profileUserState, (state) => state.userData?.id);

export const getUserError = () =>
  createSelector(profileUserState, (state) => state.error);

export const getIsUserMenuOpen = () =>
  createSelector(profileUserState, (state) => state.isUserMenuOpen);

// На бэкенде стоит ограничение отдавать 15 последних уведомлений,
// независимо от их общего количества, поэтому просто берем все уведомления из WS
export const getNotificationsFromStore = () =>
  createSelector(notificationsState, (state) => state.notifications);

// todo: бэкенд в будущем должен возвращать общее количество уведомлений из базы
export const getNotificationsFullDataLength = () =>
  createSelector(
    notificationsState,
    (state) => state.notifications?.length || 0
  );

export const getIsNotificationsListOpen = () =>
  createSelector(notificationsState, (state) => state.isNotificationsListOpen);

export const getIsNotificationsLoading = () =>
  createSelector(notificationsState, (state) => state.isLoading);
