/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const LikeS: FC<IconProps> = ({
  size = '16',
  color = '#858FA3',
  transform,
  viewBox = '0 0 16 17',
  className,
}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path 
      d="M14.2 7.21995C14.0126 6.99505 13.7782 6.814 13.5133 6.6896C13.2483 6.56519 12.9593 6.50045 12.6666 6.49995H9.62663L9.99996 5.54662C10.1553 5.1292 10.207 4.68035 10.1507 4.23856C10.0944 3.79677 9.93176 3.37523 9.67675 3.0101C9.42175 2.64497 9.08198 2.34715 8.68659 2.14218C8.2912 1.93721 7.85199 1.83121 7.40663 1.83328C7.27839 1.83355 7.15295 1.8708 7.04534 1.94056C6.93774 2.01033 6.85254 2.10965 6.79996 2.22661L4.89996 6.49995H3.3333C2.80286 6.49995 2.29416 6.71066 1.91908 7.08573C1.54401 7.46081 1.3333 7.96952 1.3333 8.49995V13.1666C1.3333 13.697 1.54401 14.2058 1.91908 14.5808C2.29416 14.9559 2.80286 15.1666 3.3333 15.1666H11.82C12.2878 15.1665 12.7408 15.0023 13.1002 14.7026C13.4595 14.403 13.7024 13.9868 13.7866 13.5266L14.6333 8.85995C14.6857 8.57153 14.674 8.27514 14.5991 7.99173C14.5242 7.70833 14.388 7.44485 14.2 7.21995ZM4.66663 13.8333H3.3333C3.15649 13.8333 2.98692 13.763 2.86189 13.638C2.73687 13.513 2.66663 13.3434 2.66663 13.1666V8.49995C2.66663 8.32314 2.73687 8.15357 2.86189 8.02854C2.98692 7.90352 3.15649 7.83328 3.3333 7.83328H4.66663V13.8333ZM13.3333 8.61995L12.4866 13.2866C12.4582 13.4419 12.3756 13.5821 12.2536 13.6822C12.1315 13.7823 11.9778 13.8359 11.82 13.8333H5.99996V7.30661L7.8133 3.22662C7.99995 3.28103 8.1733 3.37355 8.3224 3.49834C8.4715 3.62312 8.59311 3.77746 8.67956 3.95161C8.76601 4.12576 8.81542 4.31594 8.82467 4.51015C8.83392 4.70436 8.80281 4.89837 8.7333 5.07995L8.37996 6.03328C8.30468 6.23479 8.27926 6.45151 8.30588 6.66497C8.3325 6.87842 8.41038 7.08227 8.53285 7.25911C8.65533 7.43595 8.81877 7.58053 9.00923 7.68051C9.1997 7.78049 9.41152 7.83291 9.62663 7.83328H12.6666C12.7646 7.83312 12.8613 7.85455 12.9501 7.89603C13.0388 7.93751 13.1173 7.99803 13.18 8.07328C13.2442 8.1475 13.2912 8.23498 13.3177 8.32948C13.3442 8.42397 13.3496 8.52316 13.3333 8.61995Z" 
      fill={color}
    />
  </svg>
);
