import React, { FC, useEffect, Suspense, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import useSessionStorage from "@Hooks/useSessionStorage";
import { useActionsLogin } from "@Pages/LoginPage/ducks/Login.reducer";
import { defaultProject } from "@Pages/LoginPage/ducks/Login.selector";
import Loader from "@Features/Loader";

const AuthRedirect: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const project = useSelector(defaultProject());
  const { getDefaultProject } = useActionsLogin();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [value, setValue, removeValue] = useSessionStorage<null | string>(
    "referPath",
    null
  );

  const userId = Cookies.get("WSUSERID");

  const onSuccess = useCallback(
    (projectId?: string) => {
      navigate({ pathname: value ?? `/projects/${projectId}/main/news` });
      removeValue();
    },
    [value, navigate, removeValue]
  );

  const onError = useCallback(() => {
    navigate({ pathname: "/login/failed" });
  }, [navigate]);

  useEffect(() => {
    if (!userId) {
      if (pathname && pathname !== "/") {
        setValue(`${pathname}${search}`);
      }
      navigate({ pathname: "/login" });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userId && !project?.id) {
      getDefaultProject({ userId, onSuccess, onError });
    }
  }, [userId, project?.id, onSuccess, onError, getDefaultProject]);

  return (
    <Suspense fallback={<Loader />}>{project?.id ? children : null}</Suspense>
  );
};

export default AuthRedirect;
