import { FC, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  ToggleMenuButton,
  ToggleMenuTitle,
  MenuToggleIcon,
  MenuCounter,
} from "./Menu.styled";
import { useActionsMenu } from "./ducks/Menu.reducer";
import { getMenuUnfolded } from "./ducks/Menu.selector";
import { MenuToggleButtonProps } from "./types";

const MenuToggleButton: FC<MenuToggleButtonProps> = ({
  toggleShowCategoryAnchorButton,
  notificationsFeedbackCount,
}) => {
  const isUnfoldMenu = useSelector(getMenuUnfolded());

  const actionsMenu = useActionsMenu();

  const handleToggle = useCallback(() => {
    actionsMenu.toggleMainSidebarMenu();
    toggleShowCategoryAnchorButton(isUnfoldMenu ? "unlock" : "lock");
  }, [isUnfoldMenu, actionsMenu, toggleShowCategoryAnchorButton]);

  const title = `${isUnfoldMenu ? "Показать меньше" : "Показать больше"}`;

  return (
    <ToggleMenuButton onClick={handleToggle}>
      <MenuToggleIcon $isOpen={isUnfoldMenu} />
      <ToggleMenuTitle>{title}</ToggleMenuTitle>
      {!isUnfoldMenu && <MenuCounter count={notificationsFeedbackCount} />}
    </ToggleMenuButton>
  );
};

export default memo(MenuToggleButton);
