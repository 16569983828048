import { FC, memo } from "react";

import {
  ProjectContainer,
  ProjectInfoText,
  ProjectTitle,
  ProjectJobTitle,
  UnfoldProjectMenuIcon,
  UnfoldProjectMenuButton,
  ProjectInfo,
} from "./Projects.styled";
import ProjectAvatar from "./ProjectAvatar";
import { IProject } from "./types";

const ProjectHeader: FC<IProject> = ({
  id,
  name,
  roleName,
  icon,
  iconColor,
}) => {
  return (
    <ProjectContainer id={`active-project-id-${id}`}>
      <ProjectInfo>
        <ProjectAvatar name={name} icon={icon} iconColor={iconColor} />
        <ProjectInfoText>
          <ProjectTitle>{name}</ProjectTitle>
          <ProjectJobTitle>{roleName ?? ""}</ProjectJobTitle>
        </ProjectInfoText>
      </ProjectInfo>

      <UnfoldProjectMenuButton>
        <UnfoldProjectMenuIcon />
      </UnfoldProjectMenuButton>
    </ProjectContainer>
  );
};

export default memo(ProjectHeader);
