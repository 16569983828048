import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  PayloadAction,
  bindActionCreators,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";

import {
  SubmenuState,
  SubCategoriesPayload,
  SubmenuFetchPayload,
} from "../types";

const initialState: SubmenuState = {
  isLoading: [],
  categoriesSlices: { 0: [] },
  categoryOpenedIds: [],
};

const NAMESPACE = "[SubMenu]";
const FETCH_LIST = `${NAMESPACE}/fetchList`;

const fetchList = createAction<SubmenuFetchPayload>(FETCH_LIST);

const SubMenuSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    startLoading(state, { payload }: PayloadAction<string>) {
      state.isLoading.push(payload);
    },
    finishLoading(state, { payload }: PayloadAction<string>) {
      state.isLoading = state.isLoading.filter((item) => item !== payload);
    },
    mountList(
      state,
      { payload: { categories = [] } }: PayloadAction<SubCategoriesPayload>
    ) {
      state.categoriesSlices = { 0: categories };
    },
    addSubcategoryList(
      state,
      {
        payload: { categories: subCategories = [], parentId },
      }: PayloadAction<SubCategoriesPayload>
    ) {
      state.categoriesSlices[String(parentId)] = subCategories;
    },
    toggleCategoryOpened(
      state,
      { payload }: PayloadAction<SubmenuState["categoryOpenedIds"]>
    ) {
      state.categoryOpenedIds = payload;
    },
    resetCategoryOpened(state) {
      state.categoryOpenedIds = initialState.categoryOpenedIds;
    },
    resetState(state) {
      state.categoryOpenedIds = initialState.categoryOpenedIds;
      state.categoriesSlices = initialState.categoriesSlices;
    },
  },
});

export const actionsSubMenu = {
  ...SubMenuSlice.actions,
  fetchList,
};

export const useActionsSubMenu = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsSubMenu, dispatch),
    [dispatch]
  );
};

export default SubMenuSlice.reducer;
