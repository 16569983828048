import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsLogin } from "./Login.reducer";
import { ApiResponse, ResetPassAction } from "../types";

function* resetPassSaga({
  type,
  payload,
}: PayloadAction<ResetPassAction>): Generator {
  yield put(actionsLogin.startLoading(type));
  const { login, onSuccess, onError } = payload;
  try {
    const response = yield call(
      apiClient.post,
      "/v2/restore",
      {},
      { user_login: login }
    );

    const { responseStatus, code, message } = response as ApiResponse;

    if (responseStatus === 200 && code === 200) {
      onSuccess(message ?? "");
    } else {
      onError();
    }
  } catch (e) {
    onError();
    console.error("Ошибка при попытке восстановления пароля", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLogin() {
  yield takeLatest(actionsLogin.resetPassword, resetPassSaga);
}
