import { createAction } from 'redux-actions';

const prefix = '[Updates]';

export const fetchUpdatesList = createAction(`${prefix} fetchUpdatesList`);
export const mountUpdatesList = createAction(`${prefix} mountUpdatesList`);

export const fetchMoreUpdates = createAction(`${prefix} fetchMoreUpdates`);
export const mountMoreUpdates = createAction(`${prefix} mountMoreUpdates`);

export const fetchCategories = createAction(`${prefix} fetchCategories`);
export const mountCategories = createAction(`${prefix} mountCategories`);

export const filteringUpdates = createAction(`${prefix} filteringUpdates`);

export const markUpdateAsRead = createAction(`${prefix} markUpdateAsRead`);

export const markedUpdateAsRead = createAction(`${prefix} markedUpdateAsRead`);
export const abordUpdates = createAction(`${prefix} abordUpdates`);
export const catchUpdatesError = createAction(`${prefix} catchUpdatesError`);