import { combineReducers } from "redux";

import UserReducers from "../components/User/ducks/User.reducer";
import NotificationsReducers from "../components/Notifications/ducks/Notifications.reducer";

const ProfileReducers = combineReducers({
  user: UserReducers,
  notifications: NotificationsReducers,
});

export default ProfileReducers;
