import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client as StompClient } from "stompjs";
import {
  RootState,
  SendMessagePayload,
  SubscribePayload,
  Subscription,
  UnsubscribePayload,
  WebSocketState,
} from "../types";

const initialState: WebSocketState = {
  stompWS: null,
  statusWS: "offline",
};

const NAMESPACE = "[WS]";

export const webSocketState = (state: RootState) => state.webSocket;

export const stompWS = (state: WebSocketState) => state.stompWS;

export const getConnectionWS = () =>
  createSelector(
    webSocketState,
    (state) => state.stompWS?.client?.connected ?? state.statusWS === "online"
  );

const WebSocketSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    connectToWS: (state, { payload }: PayloadAction<StompClient>) => {
      state.stompWS = {
        client: payload,
        subStack: [],
        msgStack: [],
      };
      state.statusWS = "online";
    },
    setSystemStatusWS: (
      state,
      { payload }: PayloadAction<WebSocketState["statusWS"]>
    ) => {
      state.statusWS = payload;
    },
    exchangeMessagesWS: (state) => {
      const websocket = stompWS(state);

      if (websocket) {
        let { subStack, msgStack, client } = websocket;
        websocket.subStack = subStack.map(({ destination, callback, id }) => {
          if (client.connected) {
            client.subscribe(destination, callback, { id });
            return {} as Subscription;
          } else {
            return { destination, callback, id };
          }
        });
        msgStack.forEach((msg) => {
          client.send(msg.destination, msg.headers, msg.data);
        });
      }
    },
    sendMessageToWS: (
      state,
      { payload }: PayloadAction<SendMessagePayload>
    ) => {
      const websocket = stompWS(state);
      const { destination, headers, data } = payload;

      if (websocket?.client.connected) {
        websocket.client.send(destination, headers, data);
      } else {
        websocket?.msgStack.push({
          destination,
          headers,
          data,
        });
      }
    },
    subscribeWS: (state, { payload }: PayloadAction<SubscribePayload>) => {
      const websocket = stompWS(state);
      const { destination, callback, id } = payload;

      if (websocket?.client.connected) {
        websocket.client.subscribe(destination, callback, {
          id,
        });
      } else {
        websocket?.subStack.push({
          destination,
          callback,
          id,
        });
      }
    },
    unsubscribeWS: (state, { payload }: PayloadAction<UnsubscribePayload>) => {
      const websocket = stompWS(state);
      if (websocket) {
        const { subscriptions, unsubscribe } = websocket.client;
        subscriptions && Object.keys(subscriptions).forEach((key) => {
          if (key.includes(payload.id)) unsubscribe(key);
        });
      }
    },
    disconnectWS: (state) => {
      state.stompWS?.client?.disconnect(() => {
        state.stompWS = null;
        state.statusWS = "offline";
      });
    },
  },
});

export const actionsWebSocket = {
  ...WebSocketSlice.actions,
};

export const useActionsWebSocket = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsWebSocket, dispatch),
    [dispatch]
  );
};

export default WebSocketSlice.reducer;
