import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import ToggleSubMenuListButton from "../ToggleCategoryButton";
import Badge from "@/uikit/components/Badge/Badge";
import {
  MenuCounterProps,
  MenuItemContainerProps,
  MenuItemWrapperProps,
  MenuWrapperProps,
  ToggleMenuButtonProps,
} from "./types";
import { COLORS } from "@/uikit/constants/Colors";
import { FONTS } from "@/uikit/constants/Fonts";

export const MenuWrapper = styled.div<MenuWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ $lvl }) => `${12 * $lvl}px`};
  width: 100%;
  max-width: 396px;
  margin: 0;
`;

export const MenuItemIcon = styled(Icon).attrs<StyledIconProps>(({ icon }) => ({
  icon: icon ?? "ReportAccept",
  color: COLORS.neutral500,
}))`
  min-width: 20px;
`;

export const MenuToggleIcon = styled(Icon).attrs<ToggleMenuButtonProps>(
  ({ $isOpen }) => ({
    icon: $isOpen ? "ArrowMUp" : "ArrowMDown",
    color: COLORS.neutral400,
  })
)`
  margin: 0 8px 0 0;
  min-width: 20px;
`;

export const ToggleMenuTitle = styled.span`
  font-size: ${FONTS.body4strong.fs};
  font-weight: ${FONTS.body4strong.weight};
  line-height: ${FONTS.body4strong.lh};
  text-align: left;
  color: ${COLORS.neutral500};
`;

export const MenuCounter = styled(Badge).attrs({
  color: "secondary",
})<MenuCounterProps>`
  margin-left: auto;
`;

export const ToggleMenuButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  position: relative;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${COLORS.neutral10};
  outline: none;
  border: none;
  box-sizing: border-box;
  margin: 4px 8px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${COLORS.neutral50};
  }

  &:active {
    ${ToggleMenuTitle} {
      color: ${COLORS.neutral700};
    }

    ${MenuToggleIcon} {
      path {
        fill: ${COLORS.neutral500};
      }
    }
  }
`;

export const MenuSectionTitle = styled.span`
  display: flex;
  width: 100%;
  height: 32px;
  color: ${COLORS.neutral400};
  padding: 12px 24px 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  user-select: none;
`;

export const ToggleMenuItemButton = styled(ToggleSubMenuListButton)`
  display: inline-flex;
  left: 0;
`;

export const MenuItemTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${COLORS.neutral700};
  font-style: normal;
  font-size: ${FONTS.body4strong.fs};
  font-weight: ${FONTS.body4strong.weight};
  line-height: ${FONTS.body4strong.lh};
`;

export const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  margin-bottom: ${({ $isLastSectionBlock }) =>
    $isLastSectionBlock ? 0 : "4px"};
`;

export const MenuItemContainer = styled(NavLink)<MenuItemContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0 8px;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${COLORS.accent50};

          ${MenuItemTitle} {
            color: ${COLORS.accent500};
          }

          ${MenuItemIcon} {
            path {
              fill: ${COLORS.accent500};
            }
          }

          ${ToggleMenuItemButton} {
            path {
              fill: ${COLORS.accent400};
            }
          }
        `
      : css`
          &:hover {
            background-color: ${COLORS.neutral50};
            transition: all 0.2s ease-out;

            ${MenuItemIcon} {
              path {
                fill: ${COLORS.neutral700};
              }
            }
          }
        `}
`;
