import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useActionsUser } from "./ducks/User.reducer";
import { getIsLoadingUser, getUser } from "../../ducks/Profile.selector";
import SkeletonUser from "./SkeletonUser";
import {
  UserWrapper,
  // UserName,
  StyledButton,
  StyledText,
  ExitIcon,
} from "./User.styled";
// import { ArrowDownIcon } from "@Widgets/Header/Header.styled";
import { UserData } from "../../types";
import { useImagesErrorChecker } from "@Hooks/useImageErrorChecker";
import UserAvatar from "./UserAvatar";
import { useActionsLogin } from "@/pages/LoginPage/ducks/Login.reducer";

const User = () => {
  const user = useSelector(getUser());
  const isLoading = useSelector(getIsLoadingUser());

  const userActions = useActionsUser();
  const loginActions = useActionsLogin();

  useEffect(() => {
    userActions.fetchUser();
  }, [userActions]);

  const handleClick = () => {
    userActions.toggleUserMenu();

    alert("Тут будет выпадающее меню настроек пользователя MVP 1.2");
  };

  const navigate = useNavigate();

  const returnToLogin = () => {
    loginActions.cleanDefaultProject();
    navigate({ pathname: "/login" });
  };

  const handleLogout = () =>
    userActions.logoutUser({
      onSuccess: returnToLogin,
    });

  const { firstname = "", lastname = "", photo: imagePath } = user as UserData;

  const userName = `${firstname} ${lastname}`;

  const isAvatarError = useImagesErrorChecker([{ id: "user", imagePath }]);
  const isPendingAvatar = isAvatarError?.["user"] === undefined;
  const checkedAvatar = isAvatarError?.["user"] !== true ? imagePath : null;

  return (
    <SkeletonUser isLoading={isLoading || !user || isPendingAvatar}>
      <>
        <UserWrapper onClick={handleClick}>
          <UserAvatar avatar={checkedAvatar} userName={userName} />
          {/* // TODO: после доработки дизайна блок переедет в выпадающее меню
            <UserName>{userName}</UserName>
          <ArrowDownIcon />
          */}
        </UserWrapper>

        <StyledButton onClick={handleLogout}>
          <ExitIcon />
          <StyledText>Выход</StyledText>
        </StyledButton>
      </>
    </SkeletonUser>
  );
};

export default memo(User);
