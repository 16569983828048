import { FC, memo } from "react";
import {
  ToggleListButton,
  ChevroneIcon,
} from "@Widgets/Sidebar/Sidebar.styled";
import { ToggleSubMenuListButtonProps } from "./types";

const ToggleSubMenuListButton: FC<ToggleSubMenuListButtonProps> = ({
  lvl = 0,
  onToggleOpen,
  isOpen = false,
  className,
}) => (
  <ToggleListButton $lvl={lvl} onClick={onToggleOpen} className={className}>
    <ChevroneIcon $isOpenMenu={isOpen} />
  </ToggleListButton>
);

export default memo(ToggleSubMenuListButton);
