import React, { forwardRef } from 'react';
import { IconWrap, Tab } from './Tabs.styled';
import { ITabsItem } from './types';
import Text from '../Text/Text';

export const TabsItem = forwardRef<HTMLDivElement, ITabsItem>(({
  onTabClick,
  isTabActive,
  iconTab,
  element,
  display,
  p,
  m,
  children,
  ...props
}, ref) => {

  const TabItemChildrenElement = element ?? Text;
  return (
    <Tab
      ref={ref}
      onClick={onTabClick}
      $isTabActive={isTabActive}
      $display={display}
      $p={p}
      $m={m}
    >
      {iconTab && <IconWrap icon={iconTab} />}
      {/* @ts-ignore */}
      <TabItemChildrenElement {...props}>{children}</TabItemChildrenElement>
    </Tab>
  );
});

TabsItem.displayName = 'TabsItem';
