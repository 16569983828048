import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createAction,
  createSlice,
  PayloadAction,
  bindActionCreators,
} from "@reduxjs/toolkit";
import {
  DefaultProjectAction,
  DefaultProjectType,
  LdapAction,
  LoginAction,
  ResetPassAction,
  LoginState,
} from "../types";

const NAMESPACE = "[Auth]";

const initialState: LoginState = {
  isLoading: [],
  defaultProject: null,
};

const login = createAction<LoginAction>(`${NAMESPACE}/login`);
const ldap = createAction<LdapAction>(`${NAMESPACE}/ldap`);
const resetPassword = createAction<ResetPassAction>(`${NAMESPACE}/resetPassword`);
const getDefaultProject = createAction<DefaultProjectAction>(
  `${NAMESPACE}/getDefaultProject`
);

const LoginSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    startLoading: (state, { payload }: PayloadAction<string>) => {
      state.isLoading.push(payload);
    },
    finishLoading: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = state.isLoading.filter((item) => item !== payload);
    },
    setDefaultProject: (
      state,
      { payload }: PayloadAction<DefaultProjectType>
    ) => {
      state.defaultProject = payload;
    },
    cleanDefaultProject: (state) => {
      state.defaultProject = null;
    },
  },
});

export const actionsLogin = {
  ...LoginSlice.actions,
  login,
  ldap,
  resetPassword,
  getDefaultProject,
};

export const useActionsLogin = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionsLogin, dispatch), [dispatch]);
};

export default LoginSlice.reducer;
