import { useCallback, useEffect, useRef, useState } from "react";

type ItemProps = {
  id: string | number;
  imagePath?: string;
  [key: string]: any;
};

export type UseImageErrorChecker<T> = (
  items: T[],
  imagePathKey?: keyof T,
  getImagePathFromItemKey?: (imagePath: string) => string | undefined
) => Record<string | number, boolean>;

export const useImagesErrorChecker: UseImageErrorChecker<ItemProps> = (
  items,
  imagePathKey = "imagePath",
  getImagePathFromItemKey = (imagePath) => imagePath
) => {
  const [imageErrors, setImageErrors] = useState<Record<string, boolean>>({});

  const checkedImagesRef = useRef<Record<string, boolean>>({});
  const prevItemsMapRef = useRef<Map<string | number, ItemProps>>(new Map());

  const updateImageErrorStatus = useCallback(
    (id: string | number, errorStatus: boolean) => {
      setImageErrors((prevErrors) => ({
        ...prevErrors,
        [id]: errorStatus,
      }));
      checkedImagesRef.current[id] = true;
    },
    []
  );

  const getImagePath = useCallback(
    (item: ItemProps) => {
      return item?.[imagePathKey]
        ? getImagePathFromItemKey(item[imagePathKey])
        : undefined;
    },
    [getImagePathFromItemKey, imagePathKey]
  );

  useEffect(() => {
    const currentItemsMap = new Map(items.map((item) => [item.id, item]));

    const hasChanges = Array.from(currentItemsMap.keys()).some((id) => {
      return (
        !prevItemsMapRef.current.has(id) ||
        JSON.stringify(currentItemsMap.get(id)) !==
          JSON.stringify(prevItemsMapRef.current.get(id))
      );
    });

    if (hasChanges) {
      prevItemsMapRef.current = currentItemsMap;

      currentItemsMap.forEach((item, id) => {
        if (!checkedImagesRef.current[id]) {
          const imagePath = getImagePath(item);
          if (imagePath) {
            const img = new Image();
            img.src = imagePath;
            img.onload = () => updateImageErrorStatus(id, false);
            img.onerror = () => updateImageErrorStatus(id, true);
          }
        }
      });
    }
  }, [items, updateImageErrorStatus, getImagePath]);

  return imageErrors;
};
