import { ForwardedRef } from "react";
import styled from "styled-components";
import ReactCustomScrollbar from "@Features/ScrollTemplate";

import DeleteIconButton from "./DeleteIconButton";
import Icon from "@suz/ui-kit/Icon";
import { FONTS } from "@/uikit/constants/Fonts";
import { COLORS } from "@/uikit/constants/Colors";
import {
  DeleteNotificationIconProps,
  NotifictionsPopperListWrapperProps,
} from "../../types";

export const NotifictionsPopperListWrapper = styled.div<NotifictionsPopperListWrapperProps>`
  position: absolute;
  right: -36px;
  top: 2px;
  width: 372px;
  height: ${({ $height }) => `${$height}px`};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Inter, sans-serif;
  gap: 2px;
  padding: 8px 0px;
  background: #fff;
  box-shadow: 2px 2px 8px 0px #00000040;
  border-radius: 8px;
  transition: opacity 200ms ease-out;
`;

export const NotificationsScroll = styled(ReactCustomScrollbar)`
  .scrollbar {
    &__track.vertical {
      height: 100%;
      right: 0;
      margin: 0 2px;
    }

    &__thumb.vertical {
      background-color: #d0d4dc;
      border-radius: 8px;
      height: 56px;
      width: 4px;

      &:hover {
        background-color: #4453717f;
      }
    }

    &__content {
      padding: 0;
    }
  }
`;

export const NotificationsListWrapper = styled.div<{
  ref?: ForwardedRef<HTMLDivElement>;
}>`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const BlockWithoutNotifications = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${FONTS.body4.fs};
  line-height: ${FONTS.body4.lh};
  font-weight: ${FONTS.body4.weight};
  color: #445371;
`;

export const ListItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const DeleteNotificationIcon = styled(
  Icon
).attrs<DeleteNotificationIconProps>(({ disabled }) => ({
  icon: "Delete",
  size: "16",
  color: disabled ? COLORS.neutral300 : COLORS.error500,
}))`
  min-width: 16px;
  min-height: 16px;
  cursor: pointer;
`;

export const StyledDeleteNotificationIconButton = styled(DeleteIconButton)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20px;
  height: 20px;
  margin-left: 8px;
`;

const truncateTextMixin = `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 316px;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: ${FONTS.body4strong.weight};
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  color: ${COLORS.neutral950};
  ${truncateTextMixin};
`;

export const Author = styled.span`
  justify-content: flex-start;
  width: 240px;
  ${truncateTextMixin};
`;

export const DateBlock = styled.span`
  justify-content: flex-end;
  width: 100px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${Author}, ${DateBlock} {
    font-weight: ${FONTS.caption1.weight};
    font-size: ${FONTS.caption1.fs};
    line-height: ${FONTS.body4strong.lh};
    color: ${COLORS.neutral400};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HoverBlock = styled.div<{ $isRead: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  background: ${({ $isRead }) => ($isRead ? COLORS.neutral0 : COLORS.accent25)};
  transition: color 0.2s ease-out;
  gap: 8px;
  cursor: pointer;
`;

export const CreateNotificationButton = styled.div`
  font-weight: ${FONTS.body4strong.weight};
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  color: ${COLORS.neutral950};
  cursor: pointer;
  padding: 8px;
  margin: 5px 10px;
  text-align: center;
  background-color: #d0d4dc;
`;
