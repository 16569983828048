import styled from 'styled-components';

export const AppWrapper = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
`
export const AppWorkspace = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;