import React, { FC } from "react";
import { BadgeProps } from "./types";
import { Counter } from "./Badge.styled";

const Badge: FC<BadgeProps> = ({
  count = 0,
  maxCount = 999,
  color = "primary",
  Anchor = null,
  verticalPlacement = "top",
  horizontalPlacement = "right",
  onClick = () => {},
  className,
}) => {
  const counterDigits = `${count}`.length;
  const offsetByCount = (counterDigits - 1) * 5 + (count <= maxCount ? 0 : 3);
  const countToDisplay = count > maxCount ? `${maxCount}+` : count;

  return (
    <>
      {count > 0 && (
        <Counter
          color={color}
          offset={`${offsetByCount}px`}
          $hasAnchor={!!Anchor}
          $verticalPlacement={verticalPlacement}
          $horizontalPlacement={horizontalPlacement}
          className={className}
          onClick={onClick}>
          {countToDisplay}
        </Counter>
      )}
      {Anchor}
    </>
  );
};

export default Badge;
