import { call, put, all, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsLogin } from "./Login.reducer";
import { ApiResponse, LoginAction } from "../types";

function* loginSaga({ type, payload }: PayloadAction<LoginAction>): Generator {
  yield put(actionsLogin.startLoading(type));

  const { body, onSuccess, onError } = payload;

  try {
    const response = yield call(
      apiClient.post,
      "/v2/auth",
      {
        login: body.login,
        password: body.pass,
      },
      {},
      new FormData()
    );

    if ((response as ApiResponse).responseStatus === 200) {
      onSuccess();
    } else {
      onError();
    }
  } catch (e) {
    onError();
    console.error("Ошибка при попытке аутентификации", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLogin() {
  yield takeLatest(actionsLogin.login, loginSaga);
}
