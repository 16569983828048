import { FC, memo } from "react";

import { UserAvatarStyled } from "./User.styled";
import { UserAvatarProps } from "../../types";
import { getInitialsFromName } from "@/widgets/Sidebar/utils";

const UserAvatar: FC<UserAvatarProps> = ({ userName, avatar }) => {

  const initialsUser = !avatar ? getInitialsFromName(userName) : "";

  return (
    <UserAvatarStyled $avatar={avatar}>
      {initialsUser}
    </UserAvatarStyled>
  );
};

export default memo(UserAvatar);
