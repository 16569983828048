import { FC, memo, MouseEvent } from "react";

import { NotificationProps } from "../../types";
import {
  Author,
  DateBlock,
  HoverBlock,
  InfoWrapper,
  ListItemContainer,
  StyledDeleteNotificationIconButton,
  Title,
  TitleWrapper,
} from "./Notifications.styled";
import { convertDate } from "@Helpers/AppFunctions";

const Notification: FC<NotificationProps> = ({
  id,
  text,
  name,
  secName,
  date,
  isRead = true,
  onDelete,
  className,
}) => {
  // TODO: параметры isRead и disabled пока не используются, т.к. пока нет бекенда
  const disabled = false;

  const author = `${name ?? ""} ${secName ?? ""}`.trim();

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(id);
  };

  return (
    <ListItemContainer id={`notification-id-${id}`}>
      <HoverBlock $isRead={isRead}>
        <TitleWrapper>
          <Title dangerouslySetInnerHTML={{ __html: text ?? "" }} />
          <StyledDeleteNotificationIconButton
            onClick={handleDelete}
            disabled={disabled}
            className={className}
          />
        </TitleWrapper>
        <InfoWrapper>
          <Author>{author}</Author>
          <DateBlock>{convertDate(date)}</DateBlock>
        </InfoWrapper>
      </HoverBlock>
    </ListItemContainer>
  );
};

export default memo(Notification);
