import { createSelector } from "@reduxjs/toolkit";
import { LoginState } from "../types";

export const loginState = (state: { login: LoginState }) => {
  return state.login;
};

export const defaultProject = () =>
  createSelector(loginState, (state) => state.defaultProject);

export const isLoading = () =>
  createSelector(loginState, (state) => state.isLoading);
