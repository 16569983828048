// @ts-nocheck
import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import apiClient from "@Helpers/apiClient";
import * as actions from './Favorites.actions';

const OLD_PATH = '/data/kms/favorite';
const NEW_PATH = '/v2/favorites';
const TAGS_PATH = '/data/kms/project';

function* mountInitFavoritesList() {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { data: response } = yield call(apiClient.get, `${NEW_PATH}/posts`, { projectId, page: 0, size: 10, favoriteId: [] });
    yield put(actions.mountInitFavoritesList(response));
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при запросе списка обновлений', e);
  }
};

function* fetchInitFavoritesList() {
  yield takeLatest(actions.fetchInitFavoritesList, mountInitFavoritesList);
};

function* mountFolderFavoritesList({ payload }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );
    const params = payload === 0 ? [] : payload;

    const { data: response } = yield call(apiClient.get, `${NEW_PATH}/posts`, { projectId, page: 0, size: 10, folderId: params });

    yield put(actions.mountFolderFavoritesList(response));
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при запросе папки списка обновлений', e);
  }
};

function* fetchFolderFavoritesList() {
  yield takeLatest(actions.fetchFolderFavoritesList, mountFolderFavoritesList);
};

function* mountFavoritesFolders({ payload }) {
  try {
    const { data: { response } } = yield call(apiClient.get, `${OLD_PATH}/all`, { projectId: payload });

    yield put(actions.mountFavoritesFolders(response));
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при запросе списка папок', e);
  }
};

function* fetchFavoritesFolders() {
  yield takeLatest(actions.fetchFavoritesFolders, mountFavoritesFolders);
};

function* mountFolderAfterCreate({ payload }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { response } = yield call(apiClient.post, `${OLD_PATH}/edit`, { projectId, title: payload });

    yield put(actions.mountFolderAfterCreate(response));
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при создании папки в избранном', e);
  }
}

function* createFolderInFavorites() {
  yield takeLatest(actions.createFolderInFavorites, mountFolderAfterCreate);
}

function* mountFolderAfterEdit({ payload: { folderId, folderName } }) {
  try {
    yield call(apiClient.put, `${NEW_PATH}/folders`, { folderId, folderName });

    yield put(actions.mountFolderAfterEdit({ folderId, folderName }));
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при редактировании папки в избранном', e);
  }
}

function* editFolderInFavorites() {
  yield takeLatest(actions.editFolderInFavorites, mountFolderAfterEdit);
}

function* mountFoldersAfterDelete({ payload }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { code } = yield call(apiClient.post, `${OLD_PATH}/delete`, {}, { projectId, favoriteIds: payload });

    code >= 200 && code < 300 ? yield put(actions.mountFoldersAfterDelete(payload)) : yield put(actions.catchFavoritesError());
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при удалении папки в избранном', e);
  }
}

function* deleteFolderFromFavorites() {
  yield takeLatest(actions.deleteFolderFromFavorites, mountFoldersAfterDelete);
}

function* mountListAfterDelete({ payload }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { code } = yield call(apiClient.post, `${OLD_PATH}/post/delete`, {}, { projectId, postId: payload });

    code >= 200 && code < 300 ? yield put(actions.mountListAfterDelete(payload)) : yield put(actions.catchFavoritesError());
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при удалении статьи из избранного', e);
  }
}

function* deletePostFromFavorites() {
  yield takeLatest(actions.deletePostFromFavorites, mountListAfterDelete);
}

function* mountTags({ payload }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { data: { items } } = yield call(apiClient.get, `${TAGS_PATH}/${projectId}/favorite/tags`, { postIds: payload });

    yield put(actions.mountTags(items));
  } catch (e) {
    yield put(actions.catchFavoritesError(e));
    console.error('Ошибка при загрузке тегов', e);
  }
}

function* fetchTags() {
  yield takeLatest(actions.fetchTags, mountTags);
}

export default function* favoritesSaga() {
  yield all([ 
    fetchInitFavoritesList(),
    fetchFavoritesFolders(),
    fetchFolderFavoritesList(),
    createFolderInFavorites(),
    editFolderInFavorites(),
    deleteFolderFromFavorites(),
    deletePostFromFavorites(),
    fetchTags()
  ]);
};