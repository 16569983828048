// @ts-nocheck
import { handleActions } from 'redux-actions';
import {
  fetchUpdatesList, 
  mountUpdatesList,
  fetchMoreUpdates,
  mountMoreUpdates,
  fetchCategories,
  mountCategories,
  filteringUpdates,
  markUpdateAsRead,
  abordUpdates,
  catchUpdatesError
} from './Updates.actions';

const initialState = {
  isLoading: false,
  hasError: false,
  categoryId: null,
  updates: {
    updatesList: [],
    pageNumber: 0,
    empty: false,
    last: false,
  },
  updatesCategories: [],
  isContentLoading: false,
};

const UpdatesReducers = handleActions(
  {
    [fetchUpdatesList]: (state) => ({ ...state, isLoading: true }),
    [mountUpdatesList]: (state, { payload: { content, empty } }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      updates: {
        ...state.updates,
        updatesList: content,
        empty: empty,
      }
    }),
    [fetchCategories]: (state) => ({ ...state }),
    [mountCategories]: (state, { payload: updatesCategories }) => ({
      ...state,
      updatesCategories: updatesCategories

    }),
    [filteringUpdates]: (state, { payload: categoryId }) => ({
      ...state,
      categoryId: categoryId,
      updates: {
        ...state.updates,
        pageNumber: 0,
      }
    }),
    [fetchMoreUpdates]: (state, { payload: { page } }) => ({
      ...state,
      isContentLoading: true,
      updates: {
        ...state.updates,
        pageNumber: page,
      }
    }),
    [mountMoreUpdates]: (state, { payload: { content, number, last } }) => ({
      ...state,
      updates: {
        ...state.updates,
        updatesList: [...state.updates?.updatesList, ...content],
        pageNumber: number,
        last: last,
      },
      isContentLoading: false,
    }),
    [markUpdateAsRead]: (state, { payload: { postId } }) => {
      const newUpdatesList = state.updates.updatesList?.map((item) => 
        item.postId === postId ? {...item, required: false} : item);
      return { 
        ...state,
        updates: {
          ...state.updates,
          updatesList: newUpdatesList
        }
      }
    },
    [abordUpdates]: (state) => ({ 
      ...initialState
    }),
    [catchUpdatesError]: (state) => ({
      ...state,
      isLoading: false,
      hasError: true
    }),
  },
  initialState,
);

export default UpdatesReducers;