import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { ActiveMenuItem, SidebarParamsState } from "../types";
import { MENU_ITEMS } from "../components/Menu/consts";

const NAMESPACE = "[Sidebar]";

const initialState: SidebarParamsState = {
  sidebarShow: true,
  showCategoryAnchorButton: false,
  activeMenuItem: { isSubmenu: false, id: MENU_ITEMS.MAIN, isArchive: false },
};

const SidebarSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    toggleShowSidebar(state) {
      state.sidebarShow = !state.sidebarShow;
    },
    showCategoryAnchorButton(state, { payload }: PayloadAction<boolean>) {
      state.showCategoryAnchorButton = payload;
    },
    setActiveMenuItem(state, { payload }: PayloadAction<ActiveMenuItem>) {
      state.activeMenuItem = payload;
    },
    setActiveMainPage(state) {
      state.activeMenuItem = initialState.activeMenuItem;
    },
    resetState(state) {
      Object.assign(state, initialState);
      state.activeMenuItem = initialState.activeMenuItem;
    },
  },
});

export const actionsSidebar = {
  ...SidebarSlice.actions,
};

export const useActionsSidebar = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsSidebar, dispatch),
    [dispatch]
  );
};

export default SidebarSlice.reducer;
