
import React, { FC, memo } from 'react';
import { SkeletonWrapper } from '@Features/Skeleton/SkeletonWrapper';
import Skeleton from 'react-loading-skeleton';
import './styles.css';

interface LoaderProps {
  isLoading: boolean;
  children?: JSX.Element;
  count?: number;
  height?: number | string;
  width?: number | string;
  inline?: boolean;
  styles?: { [key: string]: string };
}

const Loader: FC<LoaderProps> = ({
  isLoading,
  children,
  count = 1,
  height = 36,
  width,
  inline = false,
  styles,
}): JSX.Element => (
  <>
    {isLoading || !children ? (
      <SkeletonWrapper>
        <Skeleton
          count={count}
          containerClassName="menu-skeleton-wrapper"
          height={height}
          width={width}
          inline={inline}
          style={{ marginBottom: '0px', ...styles }}
        />
      </SkeletonWrapper>
    ) : (
      children
    )}
  </>
);

export default memo(Loader);
