// @ts-nocheck
import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import apiClient from "@Helpers/apiClient";
import * as actions from './Updates.actions';

const MAIN_PATH = '/v2/projects';

// Загрузка списка обновлений

function* mountUpdatesList() {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );
    const categoryId = yield select(
      (state) => state.main.updates.categoryId
    );

    let reqParams = categoryId > 0 ? { page: 0, size: 10, categoryId: categoryId } : { page: 0, size: 10 };
    const { data: response } = yield call(apiClient.get, `${MAIN_PATH}/${projectId}/updates`, { ...reqParams });
    yield put(actions.mountUpdatesList(response));
  } catch (e) {
    yield put(actions.catchUpdatesError(e));
    console.error('Ошибка при запросе списка обновлений', e);
  }
};

function* fetchUpdatesList() {
  yield takeLatest(actions.fetchUpdatesList, mountUpdatesList);
};

// Пагинация

function* mountMoreUpdates({ payload: { page } }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );
    const categoryId = yield select(
      (state) => state.main.updates.categoryId
    );

    let reqParams = categoryId > 0 ? { page: page, size: 10, categoryId: categoryId } : { page: page, size: 10 };
    const { data } = yield call(
      apiClient.get,
      `${MAIN_PATH}/${projectId}/updates`,
      { ...reqParams }
    );
    yield put(actions.mountMoreUpdates(data));
  } catch (e) {
    yield put(actions.catchUpdatesError(e));
    console.error("Ошибка при запросе списка обновлений", e);
  }
};

function* fetchMoreUpdates() {
  yield takeLatest(actions.fetchMoreUpdates, mountMoreUpdates);
};

// Загрузка категорий для фильтра

function* mountCategories({ payload: projectId }) {
  try {

    const { data: response } = yield call(apiClient.get, `${MAIN_PATH}/${projectId}/categories`, {});
    yield put(actions.mountCategories(response));
  } catch (e) {
    yield put(actions.catchUpdatesError(e));
    console.error(`Ошибка при запросе списка категории обновлений для фильтра`, e);
  }
}

function* fetchCategories() {
  yield takeLatest(actions.fetchCategories, mountCategories);
}

// Отметка о прочтении обновления

function* markAsRead({ payload: { updateId } }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    yield call(
      apiClient.post,
      `${MAIN_PATH}/${projectId}/updates/read-receipt`,
      {},
      { updateId }
    );
    yield put(actions.markedUpdateAsRead(updateId));
  } catch (e) {
    yield put(actions.catchUpdatesError(e));
    console.error("Ошибка при отправке запроса о прочтении обновления", e);
  }
}

function* markUpdateAsRead() {
  yield takeLatest(actions.markUpdateAsRead, markAsRead);
}

export default function* newsSaga() {
  yield all([ 
    fetchUpdatesList(),
    fetchMoreUpdates(),
    fetchCategories(),
    markUpdateAsRead()
  ]);
};