import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { useActionsProjects } from "./components/ProjectsMenu/ducks/Projects.reducer";
import { useActionsSidebar } from "./ducks/Sidebar.reducer";
import { getSelectedProject } from "./components/ProjectsMenu/ducks/Projects.selector";
import {
  getSidebarVisibility,
  getLockedCategoryAnchorButton,
} from "./ducks/Sidebar.selector";
import { getMenuUnfolded } from "./components/Menu/ducks/Menu.selector";

import ProjectsMenu from "./components/ProjectsMenu/ProjectsMenu";
import Menu from "./components/Menu/Menu";
import SubMenu from "./components/SubMenu/SubMenu";
import { SidebarWrapper, MenusWrapper, SidebarScroll } from "./Sidebar.styled";
import { Params } from "./types";
import { ToggleShowCategoryAnchorButton } from "./components/Menu/types";

const Sidebar = () => {
  let { projectId } = useParams<Params>();

  const sidebarShow = useSelector(getSidebarVisibility());
  const isShowCategoryAnchorButton = useSelector(
    getLockedCategoryAnchorButton()
  );
  const isUnfoldMenu = useSelector(getMenuUnfolded());

  const project = useSelector(getSelectedProject());

  const projectsActions = useActionsProjects();
  const sidebarActions = useActionsSidebar();

  const sidebarScrollRef = useRef<any>(null);

  const toggleShowCategoryAnchorButton: ToggleShowCategoryAnchorButton = (
    forceToggleLock
  ) => {
    setTimeout(() => {
      if (sidebarScrollRef.current?.view) {
        const scrollBoxBlock = sidebarScrollRef?.current?.view as HTMLElement;
        const menuBlock = scrollBoxBlock?.firstChild as HTMLElement;
        const submenuTitleBlock = scrollBoxBlock?.childNodes[1] as HTMLElement;

        const scrollBlockClientHeigth = scrollBoxBlock?.clientHeight ?? 0;
        const menuBlockClientHeigth = menuBlock?.clientHeight ?? 0;
        const subMenuTitleOffsetTop = submenuTitleBlock?.offsetTop ?? 0;

        const forceToggle =
          scrollBlockClientHeigth <= subMenuTitleOffsetTop + 36
            ? forceToggleLock
            : false;

        const checkConditions = () => {
          if (forceToggle === "unlock") {
            return false;
          } else if (forceToggle === "lock") {
            return true;
          } else {
            return (
              isUnfoldMenu &&
              menuBlockClientHeigth > subMenuTitleOffsetTop &&
              menuBlockClientHeigth >= scrollBlockClientHeigth - 36
            );
          }
        };

        sidebarActions.showCategoryAnchorButton(checkConditions());
      }
    }, 0);
  };

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      toggleShowCategoryAnchorButton();
    },
    [toggleShowCategoryAnchorButton]
  );

  const handleScrollTopCategories = useCallback(() => {
    if (sidebarScrollRef.current?.view && isShowCategoryAnchorButton) {
      sidebarScrollRef.current.scrollTop(
        sidebarScrollRef?.current?.view?.firstChild?.clientHeight ?? 0
      );
    }
  }, [isShowCategoryAnchorButton, sidebarScrollRef]);

  useEffect(() => {
    if (projectId) {
      projectsActions.fetchSelectedProject({ projectId: +projectId });
    }
    return () => {
      sidebarActions.resetState();
      projectsActions.resetState();
    };
  }, [projectId, projectsActions, sidebarActions]);

  return (
    <SidebarWrapper $sidebarShow={sidebarShow}>
      <ProjectsMenu project={project} />
      {project?.id && (
        <MenusWrapper>
          <SidebarScroll
            ref={sidebarScrollRef}
            contentBlockId="scrollBoxBlock"
            autoHide
            onScroll={handleScroll}>
            <Menu
              projectId={project.id}
              toggleShowCategoryAnchorButton={toggleShowCategoryAnchorButton}
            />

            <SubMenu
              projectId={project.id}
              showCategoryAnchorButton={isShowCategoryAnchorButton}
              onScrollTopCategories={handleScrollTopCategories}
            />
          </SidebarScroll>
        </MenusWrapper>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
