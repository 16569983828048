/* eslint-disable */
import { FC, memo } from "react";

import {
  SubMenuListTitle,
  SubMenuListTitleContainer,
  SubMenuTitleButton,
  SubMenuCreateIcon,
  SubMenuArrowUpIcon,
  SubMenuListTitleLeftPartContainer,
} from "./SubMenu.styled";
import { SubmenuTitleBlockProps } from "./types";

const SubMenuTitleBlock: FC<SubmenuTitleBlockProps> = ({
  isBottomLocked,
  onCategoryAdd,
  onScrollTopCategories,
  isGlobalAdmin = false,
}: SubmenuTitleBlockProps) => (
  <SubMenuListTitleContainer $isBottomLocked={isBottomLocked}>
    <SubMenuListTitleLeftPartContainer onClick={onScrollTopCategories}>
      {isBottomLocked && (
        <SubMenuTitleButton>
          <SubMenuArrowUpIcon />
        </SubMenuTitleButton>
      )}
      <SubMenuListTitle>KАТЕГОРИИ</SubMenuListTitle>
    </SubMenuListTitleLeftPartContainer>
    {isGlobalAdmin && (
      <SubMenuTitleButton onClick={onCategoryAdd}>
        <SubMenuCreateIcon />
      </SubMenuTitleButton>
    )}
  </SubMenuListTitleContainer>
);

export default memo(SubMenuTitleBlock);
