import { useEffect, memo, FC, useCallback } from "react";
import { useSelector } from "react-redux";

import { useActionsSubMenu } from "./ducks/SubMenu.reducer";
import { getMenuUnfolded } from "../Menu/ducks/Menu.selector";
import {
  getLoadingStatusCategories,
  getTopLevelCategories,
} from "./ducks/SubMenu.selector";

import { SubMenuWrapper, SubMenuDragContainer } from "./SubMenu.styled";
import SubmenuSection from "./components/SubmenuSection";
import Loader from "../Loader";
import SubMenuTitleBlock from "./SubMenuTitleBlock";
import { SubmenuProps } from "./types";
import { SubmenuFetchListTriggerProps } from "./components/SubmenuSection/types";
// import { hasGlobalAdminAccess } from "../../utils";

const SubMenu: FC<SubmenuProps> = ({
  projectId,
  showCategoryAnchorButton,
  onScrollTopCategories,
}) => {
  const isGlobalAdmin = false; //  hasGlobalAdminAccess()
  // кнопки временно отключены для всех пользователей при подготовке к ПСИ

  const isOpen = useSelector(getMenuUnfolded());
  const isLoading = useSelector(getLoadingStatusCategories({ parentId: 0 }));
  const topLevelCategories = useSelector(getTopLevelCategories());

  const subMenuActions = useActionsSubMenu();

  const fetchSubMenuList = useCallback<SubmenuFetchListTriggerProps>(
    (id) => {
      if (projectId) {
        subMenuActions.fetchList({ projectId, parentCategoryId: id });
      }
    },
    [projectId, subMenuActions]
  );

  useEffect(() => {
    fetchSubMenuList(0);
    return () => {
      subMenuActions.resetState();
    };
  }, [projectId, fetchSubMenuList, subMenuActions]);

  const handleCategoryAddClick = useCallback(() => {
    alert("Будет реализовано модальное окно добавления категории");
  }, []);

  return (
    <>
      {!isLoading && (
        <SubMenuTitleBlock
          isBottomLocked={showCategoryAnchorButton}
          onScrollTopCategories={onScrollTopCategories}
          onCategoryAdd={handleCategoryAddClick}
          isGlobalAdmin={isGlobalAdmin}
        />
      )}
      <Loader
        isLoading={!!isLoading && !isOpen}
        count={15}
        styles={{ marginBottom: "8px" }}>
        <SubMenuWrapper
          id="submenuBlock"
          $isBottomLocked={showCategoryAnchorButton}>
          <SubMenuDragContainer>
            {topLevelCategories?.map(({ id, name, hasChildren }) => (
              <SubmenuSection
                key={id}
                id={id}
                linkPrefix={`/projects/${projectId}/categories`}
                name={name}
                hasChildren={hasChildren}
                onToggle={fetchSubMenuList}
                isGlobalAdmin={isGlobalAdmin}
              />
            ))}
          </SubMenuDragContainer>
        </SubMenuWrapper>
      </Loader>
    </>
  );
};

export default memo(SubMenu);
