import { memo, useEffect, FC, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import { useActionsMenu } from "./ducks/Menu.reducer";
import {
  getLoadingStatusMenu,
  getMenu,
  getMenuUnfolded,
  getNotificationCount,
} from "./ducks/Menu.selector";

import MenuItem from "./MenuItem";
import { MenuWrapper, MenuSectionTitle } from "./Menu.styled";
import Loader from "../Loader";
import MenuToggleButton from "./MenuToggleButton";
import { MenuItemMapped, MenuProps } from "./types";
import { MENU_ITEMS, menuItemMapping } from "./consts";

const Menu: FC<MenuProps> = ({
  projectId,
  parentId = 0,
  lvl = 0,
  toggleShowCategoryAnchorButton = () => {},
}) => {
  const menu = useSelector(getMenu());
  const mappedMenu = useMemo(
    () => menu.map(({ id }) => ({ id, ...menuItemMapping[id] })),
    [menu]
  );

  const isLoading = useSelector(getLoadingStatusMenu());
  const isUnfoldMenu = useSelector(getMenuUnfolded());

  const menuActions = useActionsMenu();

  const notificationsFeedbackCount = useSelector(getNotificationCount());
  const isTopLevelMenu = parentId === 0;

  const visibleMenu = useMemo(() => {
    const [first, second] = mappedMenu;

    if (!menu.length) return [];

    if (!isUnfoldMenu && isTopLevelMenu) return [first, second];

    const filterByParent = (menuItem: MenuItemMapped) =>
      isTopLevelMenu ? !menuItem.parentId : menuItem.parentId === parentId;

    return mappedMenu.filter(filterByParent);
    // eslint-disable-next-line
  }, [isUnfoldMenu, menu, parentId]);

  useEffect(() => {
    if (isTopLevelMenu && projectId) menuActions.fetchMenu({ projectId });
    return () => {
      isTopLevelMenu && menuActions.resetState();
    };
  }, [projectId, menuActions, isTopLevelMenu]);

  useEffect(() => {
    menuActions.fetchNotificationsFeedback();
  }, [menuActions]);

  const hasChildren = useCallback(
    (id: number) => menu.some((menuItem) => menuItem?.parentId === id),
    [menu]
  );

  const isLastSectionBlock = useCallback(
    (index: number) =>
      index === visibleMenu.length - 1 || !!visibleMenu[index + 1]?.isSection,
    [visibleMenu]
  );

  return (
    <Loader isLoading={isLoading} count={3} styles={{ marginBottom: "8px" }}>
      <MenuWrapper id="menuBlock" $lvl={lvl}>
        {visibleMenu?.map(({ id, isSection, title, icon, linkEnd }, index) =>
          isSection ? (
            <MenuSectionTitle key={id}>{title}</MenuSectionTitle>
          ) : (
            <MenuItem
              key={id}
              id={id}
              projectId={projectId}
              linkEnd={linkEnd}
              title={title}
              icon={icon}
              hasChildren={hasChildren(id)}
              isLastSectionBlock={isLastSectionBlock(index)}
              lvl={lvl}
              notificationCount={
                id === MENU_ITEMS.FEEDBACK
                  ? notificationsFeedbackCount
                  : undefined
              }
            />
          )
        )}
        {isTopLevelMenu && (
          <MenuToggleButton
            notificationsFeedbackCount={notificationsFeedbackCount}
            toggleShowCategoryAnchorButton={toggleShowCategoryAnchorButton}
          />
        )}
      </MenuWrapper>
    </Loader>
  );
};

export default memo(Menu);
