import { memo } from "react";

import { HeaderWrapper, BreadCrumbsPanel, UserPanel } from "./Header.styled";

import { SidebarToggle, User, Settings, Notifications } from "./components";

const Header = () => {
  return (
    <HeaderWrapper>
      <BreadCrumbsPanel>
        <SidebarToggle />
      </BreadCrumbsPanel>
      <UserPanel>
        <Notifications />
        {/* Скрываем иконку до MVP 1.2 (когда будет реализован функционал кнопки)
          <Settings />
        */}
        <User />
      </UserPanel>
    </HeaderWrapper>
  );
};

export default memo(Header);
