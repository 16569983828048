import { useMemo } from "react";
import { useImagesErrorChecker } from "@Hooks/useImageErrorChecker";
import { useProjectImageErrorsProps } from "./types";

export const useProjectImageErrors = ({
  open,
  projects,
  activeProject,
}: useProjectImageErrorsProps) => {
  const imagePathKey = "icon";
  const getImagePathFromItemKey = (imagePath: string) =>
    `/uploads/icons/${imagePath}`;

  const headerIconImgError = useImagesErrorChecker(
    [activeProject],
    imagePathKey,
    getImagePathFromItemKey
  );

  const projectsListIconsImgErrors = useImagesErrorChecker(
    open ? projects : [],
    imagePathKey,
    getImagePathFromItemKey
  );

  const iconHeaderChecked = useMemo(() => {
    return activeProject?.id && headerIconImgError[activeProject.id]
      ? undefined
      : activeProject?.icon;
  }, [headerIconImgError, activeProject?.id, activeProject?.icon]);

  const iconsListChecked = useMemo(() => {
    return projects.map((project) =>
      projectsListIconsImgErrors[project.id] ? undefined : project.icon
    );
  }, [projectsListIconsImgErrors, projects]);

  return {
    iconHeaderChecked,
    iconsListChecked,
  };
};
