import React, { forwardRef, UIEvent, useEffect, useRef } from "react";
import classnames from "classnames";
import { Scrollbars } from "react-custom-scrollbars-2";

type ScrollProps = {
  autoHide?: boolean;
  hide?: boolean;
  className?: string;
  classNameContent?: string;
  onUpdate?: () => void;
  onScroll?: (e: UIEvent<HTMLElement>) => void;
  onLoadMore?: () => void;
  children: React.ReactNode;
  autoHeight?: boolean;
  autoHeightMin?: string | number;
  autoHeightMax?: string | number;
  autoHideTimeout?: number;
  contentBlockId?: string;
};

const ReactCustomScrollbar = forwardRef<Scrollbars, ScrollProps>(
  (
    {
      autoHide,
      hide,
      className,
      classNameContent,
      onUpdate,
      onScroll,
      children,
      autoHeight,
      autoHeightMin,
      autoHeightMax,
      autoHideTimeout,
      contentBlockId,
      onLoadMore,
      ...props
    },
    ref
  ) => {
    const loadMoreRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      let observerRefValue: HTMLDivElement | null = null;
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) onLoadMore?.();
      });

      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current);
        observerRefValue = loadMoreRef.current;
      }

      return () => {
        if (observerRefValue) observer.unobserve(observerRefValue);
      };
    }, [onLoadMore]);

    return (
      <Scrollbars
        ref={ref}
        onScroll={onScroll}
        className={classnames("scrollbar__wrapper", className)}
        autoHeight={autoHeight}
        autoHeightMin={autoHeightMin}
        autoHeightMax={autoHeightMax}
        autoHide={autoHide}
        autoHideTimeout={autoHideTimeout}
        thumbMinSize={40}
        hideTracksWhenNotNeeded={hide}
        onUpdate={onUpdate}
        renderTrackVertical={(scrollProps) => (
          <div {...scrollProps} className="scrollbar__track vertical" />
        )}
        renderThumbVertical={(scrollProps) => (
          <div {...scrollProps} className="scrollbar__thumb vertical" />
        )}
        renderTrackHorizontal={(scrollProps) => (
          <div {...scrollProps} className="scrollbar__track horizontal" />
        )}
        renderThumbHorizontal={(scrollProps) => (
          <div {...scrollProps} className="scrollbar__thumb horizontal" />
        )}
        renderView={(scrollProps) => (
          <div
            {...scrollProps}
            {...(contentBlockId && { id: contentBlockId })}
            className={classnames("scrollbar__content", classNameContent)}
          />
        )}
        {...props}>
        {children}
        <div ref={loadMoreRef} style={{ width: "100%" }} />
      </Scrollbars>
    );
  }
);

export default ReactCustomScrollbar;
