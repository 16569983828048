export const COLORS = {
  neutral0: '#fff',
  neutral10: '#F9FAFB',
  neutral25: '#F6F6F8',
  neutral50: '#ECEEF1',
  neutral75: '#DFE2E7',
  neutral100: '#D0D4DC',
  neutral200: '#A1A9B8',
  neutral300: '#858FA3',
  neutral400: '#69758E',
  neutral500: '#445371',
  neutral600: '#3A4760',
  neutral700: '#303A4F',
  neutral800: '#293244',
  neutral900: '#222A39',
  neutral950: '#181D28',
  neutral1000: '#101828',

  accent10: '#F7FAFF',
  accent25: '#F2F7FF',
  accent50: '#E6EEFF',
  accent75: '#D1E0FF',
  accent100: '#BFD4FF',
  accent200: '#80AAFF',
  accent300: '#5991FF',
  accent400: '#3377FF',
  accent500: '#0055FF',
  accent600: '#0048D9 ',
  accent700: '#003BB3',
  accent800: '#003399',
  accent900: '#002B80',
  accent950: '#001E59',

  success10: '#F7FCF9',
  success25: '#F2FAF5',
  success50: '#E6F5EC',
  success75: '#D3EEDD',
  success100: '#C0E7CE',
  success200: '#80CF9E',
  success300: '#5AC180',
  success400: '#34B263',
  success500: '#019F3C',
  success600: '#018733',
  success700: '#016F2A',
  success800: '#015F24',
  success900: '#01501E',
  success950: '#003815',

  warning10: '#FFFCF6',
  warning25: '#FFFAF1',
  warning50: '#FFF6E5',
  warning75: '#FFF0D1',
  warning100: '#FEE8BD',
  warning200: '#FED27B',
  warning300: '#FDC453',
  warning400: '#FDB72B',
  warning500: '#F2A100',
  warning600: '#CE8900',
  warning700: '#A97100',
  warning800: '#916100',
  warning900: '#795100',
  warning950: '#553900',

  error10: '#FEF8F8',
  error25: '#FEF3F2',
  error50: '#FDE7E6',
  error75: '#FCD6D4',
  error100: '#FBC3C0',
  error200: '#F7857F',
  error300: '#F46059',
  error400: '#F23D33',
  error500: '#E0190E',
  error600: '#B81A12',
  error700: '#98160F',
  error800: '#82130D',
  error900: '#6C100B',
  error950: '#4C070E',

  status_1_10: '#F8FCFF',
  status_1_25: '#F4FAFF',
  status_1_50: '#E8F5FF',
  status_1_75: '#D6EDFF',
  status_1_100: '#C6E5FF',
  status_1_200: '#8CCBFF',
  status_1_300: '#6ABCFF',
  status_1_400: '#47ADFF',
  status_1_500: '#1998FF',
  status_1_600: '#1581D9',
  status_1_700: '#116AB3',
  status_1_800: '#0F5B99',
  status_1_900: '#0C4C80',
  status_1_950: '#093559',

  status_2_10: '#F7FDFB',
  status_2_25: '#F2FBF9',
  status_2_50: '#E6F7F3',
  status_2_75: '#D4F2EB',
  status_2_100: '#BFECE1',
  status_2_200: '#80D8C4',
  status_2_300: '#59CDB2',
  status_2_400: '#33C1A0',
  status_2_500: '#00B288',
  status_2_600: '#009774',
  status_2_700: '#007D5F',
  status_2_800: '#006B52',
  status_2_900: '#005944',
  status_2_950: '#003E30',

  status_3_10: '#FFFAF7',
  status_3_25: '#FFF6F2',
  status_3_50: '#FEEEE6',
  status_3_75: '#FEE1D2',
  status_3_100: '#FED4BF',
  status_3_200: '#FCA980',
  status_3_300: '#FC8F59',
  status_3_400: '#FB7533',
  status_3_500: '#FA5300',
  status_3_600: '#D44700',
  status_3_700: '#AF3A00',
  status_3_800: '#963200',
  status_3_900: '#7D2A00',
  status_3_950: '#571D00',

  status_4_10: '#FEF9FB',
  status_4_25: '#FEF5F9',
  status_4_50: '#FCEBF3',
  status_4_75: '#FADCE9',
  status_4_100: '#F8CEE0',
  status_4_200: '#F19DC1',
  status_4_300: '#ED7FAE',
  status_4_400: '#E9619B',
  status_4_500: '#E33A82',
  status_4_600: '#C1316E',
  status_4_700: '#9F295B',
  status_4_800: '#88234E',
  status_4_900: '#711D41',
  status_4_950: '#50142D',

  status_5_10: '#FCF8FF',
  status_5_25: '#FAF4FF',
  status_5_50: '#F5E9FF',
  status_5_75: '#EFDBFF',
  status_5_100: '#E6C8FF',
  status_5_200: '#CC92FF',
  status_5_300: '#BD71FF',
  status_5_400: '#AD50FF',
  status_5_500: '#9924FF',
  status_5_600: '#821FD9',
  status_5_700: '#6B19B3',
  status_5_800: '#5C1699',
  status_5_900: '#4D1280',
  status_5_950: '#360D59',

  status_6_10: '#FAFAFF',
  status_6_25: '#F8F6FE',
  status_6_50: '#F8F6FE',
  status_6_75: '#E6E2FD',
  status_6_100: '#DAD4FC',
  status_6_200: '#B4A9FA',
  status_6_300: '#9E8FF9',
  status_6_400: '#8775F7',
  status_6_500: '#6952F5',
  status_6_600: '#5946D0',
  status_6_700: '#4939AC',
  status_6_800: '#3F3193',
  status_6_900: '#35297A',
  status_6_950: '#251D56',

  status_7_10: '#F8FCFD',
  status_7_25: '#F3FAFC',
  status_7_50: '#E7F4F8',
  status_7_75: '#D8EDF3',
  status_7_100: '#C4E4EE',
  status_7_200: '#88C8DD',
  status_7_300: '#64B8D3',
  status_7_400: '#41A8C9',
  status_7_500: '#1192BB',
  status_7_600: '#0E7C9F',
  status_7_700: '#0C6683',
  status_7_800: '#0A5870',
  status_7_900: '#08495E',
  status_7_950: '#063342',
} as const;